import React, { useState } from "react";
import CommonLayout from "../../layouts/master/index";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";

export default function Reseller() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentResellerId, setCurrentResellerId] = useState(null);
  const [selectedResellerData, setSelectedResellerData] = useState(null); 
  const [isApprove, setIsApprove] = useState(false); 
  
  const handleOpenModal = (reseller = null, isApproveModal = false) => {
    setSelectedResellerData(reseller); 
    setIsApprove(isApproveModal); 
    setShowAddModal(true); 
  };

  return (
    <>
      <CommonLayout>
        <div>
          <List
            editHandler={(id) => {
              setCurrentResellerId(id);
              setShowEditModal(true);
            }}
            handleOpenModal={handleOpenModal}
          />
          <Add
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            resellerData={selectedResellerData} 
            isApprove={isApprove}
          />
          {showEditModal && (
            <Edit
              show={showEditModal}
              handleClose={() => setShowEditModal(false)}
              resellerId={currentResellerId}
            />
          )}
        </div>
      </CommonLayout>
    </>
  );
}
