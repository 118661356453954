import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import resellerReducer from "./resellerSlice";
import organizationReducer from "./organizationSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    reseller: resellerReducer,
    organization: organizationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
