import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  // Dropdown,
  // ButtonGroup,
  ToastContainer,
  Toast, Tabs, Tab, Badge, Button, Modal
} from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import PaginationComponent from "../pagination/pagination";
import { useLoader } from "../../../context/LoaderContext";
import {
  MagnifyingGlass,
  // DotsThreeVertical,
  // Pencil,
  CheckCircle,
} from "../../../constant/icons/icons";
import { fetchRequestedCredits } from "../../../store/resellerSlice";
import Edit from "./Edit";
import moment from "moment";
import AddCredit from "./AddCredit";

export default function List() {
  const { setLoading } = useLoader();
  const limit = 10;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [activeTab, setActiveTab] = useState(null);
  // const [openDropdown, setOpenDropdown] = useState({ id: null, type: null });

  const { total, requestedCredits } = useSelector((state) => ({
    total: state.reseller?.requestedCredits?.total,
    requestedCredits: state.reseller?.requestedCredits?.data,
  })); 

  const [showModal, setShowModal] = useState(false);  
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentRequestedCreditId, setCurrentRequestedCreditId] = useState(null);
  const [descriptionModalShow, setDescriptionModalShow] = useState(false);
  const [fullDescription, setFullDescription] = useState("");
  const toastMessage = localStorage.getItem("toastMessageOrganizationType");

  useEffect(() => {
    if (toastMessage) {
      setShowSnackbar(true);
      setSnackbarMessage(toastMessage);
      setSnackbarVariant("success");
      localStorage.removeItem("toastMessageOrganizationType");

      if (toastMessage === "Validation failed") {
        setShowSnackbar(true);
        setSnackbarMessage(toastMessage);
        setSnackbarVariant("danger");
        localStorage.removeItem("toastMessageOrganizationType");
      }
    }
  }, [toastMessage]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
      role="button"
    >
      {children}
    </div>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  // const handleDropdownToggle = (id, type) => {
  //   if (openDropdown?.id === id && openDropdown.type === type) {
  //     // Close the currently open dropdown
  //     setOpenDropdown({ id: null, type: null });
  //   } else {
  //     // Open the new dropdown and close any others
  //     setOpenDropdown({ id: id, type: type });
  //   }
  // };

  // const handleEditOpen = (id) => {
  //   setCurrentRequestedCreditId(id);
  //   setShowEditModal(true);
  // };

  // const ActionMenu = ({ requestedCredit, isOpen, toggleMenu }) => (
  //   <Dropdown
  //     drop="down-centered"
  //     as={ButtonGroup}
  //     className="action-dropdown w-100 d-inline-block"
  //     show={isOpen}
  //     onToggle={() => handleDropdownToggle(requestedCredit?.id, "action")}
  //   >
  //     <Dropdown.Toggle
  //       id={`dropdown-split-basic-${requestedCredit?.id}`}
  //       className="p-0"
  //       variant="link"
  //       as={CustomToggle}
  //       onClick={(e) => {
  //         e.preventDefault();
  //         e.stopPropagation();
  //         toggleMenu(requestedCredit?.id);
  //       }}
  //     >
  //       <DotsThreeVertical size={17} color="#637381" weight="bold" />
  //     </Dropdown.Toggle>
  //     <Dropdown.Menu align="end" className="action-dr-menu z-1">
  //       <Dropdown.Item
  //         eventKey="1"
  //         className="d-flex align-items-center dr-item item-orange"
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           handleEditOpen(requestedCredit?.id)
  //         }}
  //       >
  //         <span>
  //           <Pencil size={15} color="#637381" weight="regular" />
  //         </span>
  //         <p className="mb-0">Edit</p>
  //       </Dropdown.Item>
  //     </Dropdown.Menu>
  //   </Dropdown>
  // );

  const getRequestedCreditList = useCallback(() => {
    setLoading(true);
    Promise.all([
        dispatch(
          fetchRequestedCredits({
          offset: currentPage,
          limit,
          search: searchTerm,
          status:activeTab,
          sortOrder: "desc",
          sortKey: "id",
        })),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    currentPage,
    limit,
    searchTerm,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getRequestedCreditList();
  }, [getRequestedCreditList]);

  const handleTabSelect = (key) => {
    if (key === "") {
      setCurrentPage(0);
      setActiveTab(null); 
    } else {
      setCurrentPage(0);
      setActiveTab(key);
    }
  
    setCurrentPage(0); 
  };

  const handleClose = ()=>{
    setShowEditModal(false);
  }

  const handleAddCreditShow = () => setShowModal(true);
  const handleAddCreditClose = () => {
    setShowModal(false);
  };

  const handleRowClick = (requestedCredit) => {
    setCurrentPage(0);
    setSearchTerm("");
    setCurrentRequestedCreditId(requestedCredit);
    setShowEditModal(true);
  };

  const handleReadMore = (description) => {
    setFullDescription(description);
    setDescriptionModalShow(true);
  };

  return (
    <>
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6 pb-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div>
                <h3 className="mb-0 fw-semibold pg-title text-white">
                  Requested Credits
                </h3>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative rounded-3 bg-white  tablecontainer">
              <div className="d-flex align-items-center justify-content-between gap-3 reseller-sea-btn">
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Search "
                    className="searchbar outline-none border-0 shadow-none "
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setCurrentPage(0);
                    }}
                  />
                  <MagnifyingGlass
                    size={18}
                    color="#757779"
                    className="position-absolute top-50 start-0 ms-4 translate-middle"
                    weight="regular"
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', bottom: '10px' }}>
                  <Button
                    variant="primary"
                    onClick={handleAddCreditShow}
                    // style={{ 
                    //   position: "relative",
                    //   left: "1350px",
                    //   bottom: "68px",
                    // }}
                  >
                    Add Credit
                  </Button>
              </div>

              <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-3">
                    <Tabs
                      defaultActiveKey=""
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey=""
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <UserList
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">All</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="placed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Requested</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="completed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Completed</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="rejected"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Rejected</p>
                            </div>
                          </>
                        }
                      ></Tab>
                    </Tabs>
                  </div>

              <div className="rounded-2 custom_table mt-5">
                <table className="w-100">
                  <thead>
                    <tr>               
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Reseller Name</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("name")}
                          >
                            {/* {renderSortIcons("name")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Credits</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("name")}
                          >
                            {/* {renderSortIcons("name")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Credit Rate</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("credit_rate")}
                          >
                            {/* {renderSortIcons("credit_rate")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Total Price</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("credit_rate")}
                          >
                            {/* {renderSortIcons("credit_rate")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Description</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("credit_rate")}
                          >
                            {/* {renderSortIcons("credit_rate")} */}
                          </span>
                        </div>
                      </th> 
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Status</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("credit_rate")}
                          >
                            {/* {renderSortIcons("credit_rate")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Created On</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("name")}
                          >
                            {/* {renderSortIcons("name")} */}
                          </span>
                        </div>
                      </th>
                      {/* <th className="fw-medium">
                        <div className="d-flex align-items-center justify-content-center gap-th">
                          <p className="mb-0">Action</p>
                        </div>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                  {requestedCredits?.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center border-bottom-0">
                        <p className="mt-5 mb-0 no-data text-capitalize">
                          No data available
                        </p>
                      </td>
                    </tr>
                  ) : (
                    requestedCredits?.map((requestedCredit) => (
                      <tr
                        key={requestedCredit?.id}
                        className={`position-relative ${
                          requestedCredit.status === "placed" ? "cursor-pointer" : ""
                        }`}                             
                        onClick={() => {
                          if (requestedCredit.status === "placed") {
                            handleRowClick(requestedCredit.id); 
                          }
                        }}                         >
                        <td>{requestedCredit?.reseller?.name}</td>
                        <td>{requestedCredit.credits}</td>
                        <td>{`₹${requestedCredit.credit_rate}`}</td>
                        <td>{`₹${requestedCredit.price}`}</td>
                        <td>
                          {requestedCredit.description?.length > 30 ? (
                            <>
                              {requestedCredit.description.substring(0, 30)}...
                              <span style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => {
                                  e.stopPropagation();
                                  handleReadMore(requestedCredit.description);
                                }}>
                                  Read More
                                </span>
                            </>
                          ) : (
                            requestedCredit.description || "-"
                          )}
                      </td>                       
                       <td>
                          {requestedCredit.status === "completed" ? (
                            <Badge pill bg="success">
                              Completed
                            </Badge>
                          ) : requestedCredit.status === "rejected" ? (
                            <Badge pill bg="danger">
                              Rejected
                            </Badge>
                          ) : (
                            <Badge pill bg="primary">
                              Requested
                            </Badge>
                          )}
                        </td>
                        <td>
                            {moment(requestedCredit.created_at).format("DD/MM/YYYY hh:mm A")}
                        </td>                        
                        {/* <td className="text-center">
                          {(requestedCredit.status === "placed") ? (
                            <ActionMenu
                              requestedCredit={requestedCredit}
                              isOpen={
                                openDropdown?.id === requestedCredit?.id &&
                                openDropdown.type === "action"
                              }
                              toggleMenu={() =>
                                handleDropdownToggle(
                                  requestedCredit?.id,
                                  "action"
                                )
                              }
                            />
                          ) : (
                            <span className="text-muted">No actions available</span>
                          )}
                        </td> */}
                      </tr>
                    ))
                  )}
                </tbody>
                </table>
              </div>
              {total > limit && (
                <div className="pt-4">
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={descriptionModalShow} onHide={() => setDescriptionModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>{fullDescription}</Modal.Body>
      </Modal>

      <Edit
          show={showEditModal}
          status={activeTab}
          // refreshData={getRequestedCreditList}
          handleModalClose={()=>{
            getRequestedCreditList();
            handleClose();
          }}
          requestedCreditId={currentRequestedCreditId}
          handleClose={handleClose}
        />
        <AddCredit
          show={showModal}
          handleClose={handleAddCreditClose}
          currentPage={currentPage}
          itemsPerPage={limit}
        />
    </>
  );
}
