import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";

export const fetchOrganizationTypes = createAsyncThunk(
  "organization/fetchOrganizationTypes",
  async ({ offset, limit, search, sort_by_key, sort_by_value },{ rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/organization-type", {
        params: { offset, limit, search, sort_by_key, sort_by_value },
      });

      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addOrganizationType = createAsyncThunk(
  "organization/addOrganizationType",
  async (organizationTypeData, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/admin/organization-type", organizationTypeData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateOrganizationType = createAsyncThunk(
  "organization/updateOrganizationType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/admin/organization-type/${data.get('id')}`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOrganizationType = createAsyncThunk(
  "organization/deleteOrganizationType",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.delete(`/api/admin/organization-type/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organization_types: [],
    total: 0,
    page: 1,
    limit: 10,
    totalPages: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationTypes.fulfilled, (state, { payload }) => {
        state.organization_types = payload.data || [];
        state.total = payload.total || 0;
        state.page = payload.currentPages;
        state.limit = payload.limit;
        state.totalPages = payload.totalPages;
    });
  },
});

export default organizationSlice.reducer;
