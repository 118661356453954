import React, { useEffect, useState, useCallback } from "react";
import { Col, Modal, Row, Tab, Tabs, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCampaignDetail,
  fetchGuestDetails,
} from "../../../store/resellerSlice";
import PaginationComponent from "../pagination/pagination";
import { createSelector } from "reselect";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import {
  ArrowLeft,
  UserSquare,
  // Phone,
  ChatCircleDots,
  ArrowUp,
  ArrowDown,
  MagnifyingGlass,
  CalendarPlus,
  CalendarCheck,
} from "../../../constant/icons/icons";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import { ChevronLeft, ChevronRight } from "react-feather";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CampaignDetailModal({
  show,
  campaign,
  handleClose,
  handleOrganizationClose,
  handleResellerClose,
  handleCustomerClose,
  customerDetails,
  resellerDetails,
}) {
  const { setLoading } = useLoader();
  const limit = 10;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  // const firstRender = useRef(true);
  const clientDetail = useSelector((state) => state.reseller.currentClient);
  const campaignDetail = useSelector((state) => state.reseller.currentCampaign);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [activeTab, setActiveTab] = useState("guests");
  const [documentUrl, setDocumentUrl] = useState(null);
  const [pdfTotalPages, setPdfTotalPages] = useState(0);
  const [pdfCurrentPage, setPdfCurrentPage] = useState(1);

  const campaignDetailData = useSelector((state) => state.reseller.guests);

  const selectGuestState = (state) => state.reseller.guests;

  const selectGuestData = createSelector([selectGuestState], (guests) => {
    return {
      total: guests.total,
      guests: guests.data,
    };
  });

  const { total, guests } = useSelector(selectGuestData);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     dispatch(fetchCampaignDetail(campaign.id));
  //     dispatch(
  //       fetchGuestDetails({
  //         id: campaign.id,
  //         offset: currentPage,
  //         limit,
  //         search: searchTerm,
  //         sort_by_key: sortKey,
  //         sort_by_value: sortOrder
  //       })
  //     );
  //   }
  // }, [dispatch, campaign, id, currentPage, limit, searchTerm, sortKey, sortOrder ]);

  const getGuestList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchCampaignDetail(campaign.id)),
      dispatch(
        fetchGuestDetails({
          id: campaign.id,
          offset: currentPage,
          limit,
          search: searchTerm,
          sort_by_key: sortKey,
          sort_by_value: sortOrder,
          is_test: activeTab === "guests" ? "false" : "true",
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    campaign,
    currentPage,
    limit,
    searchTerm,
    sortKey,
    sortOrder,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getGuestList();
  }, [getGuestList]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        {/* <ArrowUp
          size={16}
          onClick={() => handleSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "asc" ? "black" : "#ccc",
          }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "desc" ? "black" : "#ccc",
          }}
        /> */}

        <ArrowUp
          size={14}
          color={isActive && sortOrder === "asc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleSort(key, "asc")}
        />

        <ArrowDown
          size={14}
          color={isActive && sortOrder === "desc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleSort(key, "desc")}
        />
      </>
    );
  };

  const handleCloseModal = () => {
    setSearchTerm("");
    setCurrentPage(0);
    handleClose();
    setLoading(false);
    setActiveTab("guests");
  };

  const handleCloseOrganizationModal = () => {
    handleCloseModal();
    handleOrganizationClose();
  };

  const handleCloseCustomerModal = () => {
    handleCloseModal();
    handleOrganizationClose();
    handleCustomerClose();
  };

  const handleCloseResellerModal = () => {
    handleCloseModal();
    handleOrganizationClose();
    handleCustomerClose();
    handleResellerClose();
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);

    switch (key) {
      case "guests":
        // setSearchTerm("");
        setCurrentPage(0);
        // setCurrentCreditPage(0);
        // fetchCustomerDetailsTab();
        break;

      case "test":
        // setCurrentCreditPage(0);
        // fetchCreditDetailsTab();
        break;

      default:
        break;
    }
  };

  const fetchDocument = useCallback(
    async (url) => {
      try {
        setLoading(true);
        const response = await axios.get(url, { responseType: "blob" });
        const blobUrl = URL.createObjectURL(response.data);

        setDocumentUrl(blobUrl);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching document: ", error);
        setLoading(false);
      }
    },
    [setLoading]
  );

  const fetchCampaignByIdData = useCallback(() => {
    setLoading(true);
    dispatch(fetchCampaignDetail(campaign.id))
      .then((response) => {
        if (response.payload && response.payload?.template_url) {
          fetchDocument(response.payload?.new_template_url);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, campaign.id, setLoading, fetchDocument]);

  useEffect(() => {
    fetchCampaignByIdData();
  }, [fetchCampaignByIdData]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfTotalPages(numPages);
    setPdfCurrentPage(1);
  };

  const goToNextPage = () => {
    setPdfCurrentPage((prevPage) => Math.min(prevPage + 1, pdfTotalPages));
  };

  const goToPreviousPage = () => {
    setPdfCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleStatusClick = (status) => {
    setLoading(true);
    // setIsFailedSelected(status === "failed");
    const fetchParams = {
      offset: currentPage,
      limit,
      search: searchTerm,
      is_test: activeTab === "guests" ? "false" : "true",
      id: campaign.id,
    };

    if (status !== "all") {
      fetchParams.status = status;
    }

    dispatch(fetchGuestDetails(fetchParams)).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        fullscreen={true}
        dialogClassName=""
        contentClassName="p-6 bg-light"
      >
        <Modal.Header className="p-0 border-0  d-inline-block">
          <div className="d-flex align-items-center justify-content-between gap-3 border-bottom pb-4">
            <div className="d-flex align-items-center gap-2 primary-pg-title ">
              <Link
                to=""
                className="d-flex align-items-center justify-content-center rounded-circle back-arrow"
                onClick={handleCloseModal}
              >
                <ArrowLeft
                  size={24}
                  color="#624BFF"
                  weight="bold"
                  className="cursor-pointer"
                />
              </Link>

              <h3 className="mb-0 fw-semibold pg-title fw-medium">
                Campaign Details
              </h3>
            </div>
            <div className="d-flex align-items-center pg-header-link">
              <Link
                to=""
                className="pg-link-deactive"
                onClick={handleCloseResellerModal}
              >
                Reseller
              </Link>
              <p className="mb-0">/</p>
              <Link
                to=""
                className="pg-link-deactive text-capitalize"
                onClick={handleCloseCustomerModal}
              >
                {resellerDetails.name}
              </Link>
              <p className="mb-0">/</p>
              <Link
                to=""
                className="pg-link-deactive text-capitalize"
                onClick={handleCloseOrganizationModal}
              >
                {customerDetails.name}
              </Link>
              <p className="mb-0">/</p>
              <Link
                to=""
                className="pg-link-deactive text-capitalize"
                onClick={handleCloseModal}
              >
                {clientDetail?.name}
              </Link>
              <p className="mb-0">/</p>
              <Link to="#" className="text-capitalize">
                {campaignDetail.name}
              </Link>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-5 mt-0">
            <Col xl={4} lg={12} md={12} xs={12}>
              <Row className="gy-4">
                <Col xs={12} md={12} lg={12} xl={12}>
                  <div className="bg-white rounded-3 p-4 h-100">
                    <div>
                      <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                        <span className="rounded-circle"></span>
                        <p className="mb-0">Campaign Information</p>
                      </div>
                      <div className="d-flex flex-column mt-3 reseller-de-card">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <UserSquare
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Name :{" "}
                            </p>
                          </div>
                          <p className="mb-0">{campaignDetail.name}</p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <ChatCircleDots
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 white-space-nowrap">
                              Message :{" "}
                            </p>
                          </div>
                          <p className="mb-0"> {campaignDetail.message}</p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <ChatCircleDots
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 white-space-nowrap">Status : </p>
                          </div>
                          <p className="mb-0 text-capitalize"> {campaignDetail.status}</p>
                        </div>

                        {/* <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Phone size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Mobile No. :
                            </p>
                          </div>
                          <p className="mb-0 text-capitalize">
                            {clientDetail?.whatsapp_client?.mobile_number}
                          </p>
                        </div> */}

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <CalendarPlus
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Created on :
                            </p>
                          </div>
                          <p className="mb-0 text-capitalize">
                            {moment(campaignDetail.created_at).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <CalendarCheck
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Updated on :
                            </p>
                          </div>
                          <p className="mb-0 text-capitalize">
                            {moment(campaignDetail.updated_at).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <div className="bg-white rounded-3 p-4 h-100">
                    <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                      <span className="rounded-circle"></span>
                      <p className="mb-0">Document</p>
                    </div>
                    <div className="document-viewer mt-3">
                      {documentUrl ? (
                        campaign?.template_type === "pdf" ? (
                          <>
                            <Document
                              file={documentUrl}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onLoadError={(error) =>
                                console.log(
                                  "Error while loading document:",
                                  error
                                )
                              }
                              onSourceError={(error) =>
                                console.log(
                                  "Error while fetching document source:",
                                  error
                                )
                              }
                              className="d-flex justify-content-center"
                            >
                              <Page
                                pageNumber={pdfCurrentPage}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                              />
                            </Document>
                            <div className="d-flex justify-content-center mb-3">
                              <Button
                                variant="outline-primary"
                                className="me-2"
                                onClick={goToPreviousPage}
                                disabled={pdfCurrentPage === 1}
                              >
                                <ChevronLeft />
                              </Button>
                              <div className="d-flex align-items-center">
                                Page {pdfCurrentPage} of {pdfTotalPages}
                              </div>
                              <Button
                                variant="outline-primary"
                                className="ms-2"
                                onClick={goToNextPage}
                                disabled={pdfCurrentPage === pdfTotalPages}
                              >
                                <ChevronRight />
                              </Button>
                            </div>
                          </>
                        ) : campaignDetail?.template_type?.startsWith(
                            "image"
                          ) ? (
                          <img
                            src={documentUrl}
                            alt="Campaign Document"
                            style={{ maxWidth: "100%" }}
                          />
                        ) : (
                          <p>Unsupported document type</p>
                        )
                      ) : (
                        <p>No document available</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={12} xl={8}>
              <div>
                <div className="position-relative overflow-hidden rounded-2 bg-white tablecontainer">
                  <div className="d-flex align-items-center justify-content-between mb-4 table-tit-head">
                    <p className="m-0">User Guest List</p>
                    <div className="position-relative">
                      <input
                        type="search"
                        placeholder="Search"
                        className="rounded-1"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setCurrentPage(0);
                        }}
                      />
                      <MagnifyingGlass
                        size={18}
                        color="#757779"
                        className="position-absolute top-50 start-0 ms-4 translate-middle"
                        weight="regular"
                      />
                    </div>
                  </div>

                  <Row className="my-5 g-4 cerdit-logs-content">
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card h-100 cursor-pointer"
                        // className=""
                        style={{
                          borderStyle: "groove",
                        }}
                        onClick={() => handleStatusClick("all")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="mb-0 text-capitalize">
                              Total Recipients
                            </p>
                            <span>
                              {activeTab === "guests"
                                ? campaignDetailData?.total_non_tested
                                : campaignDetailData?.total_tested || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                        style={{
                          borderStyle: "groove",
                        }}
                        onClick={() => handleStatusClick("pending")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">In Queue</p>
                            <span>
                              {campaignDetailData?.total_pending || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                        style={{
                          borderStyle: "groove",
                        }}
                        onClick={() => handleStatusClick("sent")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Sent</p>
                            <span>{campaignDetailData?.total_sent || 0}</span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                        style={{
                          borderStyle: "groove",
                        }}
                        onClick={() => handleStatusClick("delivered")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Delivered</p>
                            <span>
                              {campaignDetailData?.total_delivered || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                        style={{
                          borderStyle: "groove",
                        }}
                        onClick={() => handleStatusClick("seen")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Seen</p>
                            <span>{campaignDetailData?.total_read || 0}</span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                        style={{
                          borderStyle: "groove",
                        }}
                        onClick={() => handleStatusClick("failed")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Failed</p>
                            <span>{campaignDetailData?.total_failed || 0}</span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card h-100 cursor-pointer"
                        style={{
                          borderStyle: "groove",
                        }}
                        onClick={() => handleStatusClick("invalid")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Invalid</p>
                            <span>
                              {campaignDetailData?.total_invalid || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-3">
                    <Tabs
                      defaultActiveKey="guests"
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey="guests"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <UserList
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Guest</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="test"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Test</p>
                            </div>
                          </>
                        }
                      ></Tab>
                    </Tabs>
                  </div>

                  <div className="table-responsive custom_table rounded-2">
                    <table className="w-100 rounded-2 overflow-hidden">
                      <thead>
                        <tr>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Name</p>
                              <span className="d-flex align-items-center">
                                {renderSortIcons("name")}
                              </span>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Mobile Number</p>
                              <span className="d-flex align-items-center">
                                {renderSortIcons("mobile_number")}
                              </span>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Date</p>
                              <span className="d-flex align-items-center">
                              </span>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Schedule Date</p>
                              <span className="d-flex align-items-center">
                              </span>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Status</p>
                              <span className="d-flex align-items-center">
                              </span>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Failed Reason</p>
                              <span className="d-flex align-items-center">
                              </span>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Credits Used</p>
                              <span className="d-flex align-items-center">
                                {renderSortIcons("credits")}
                              </span>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Delivered</p>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Seen</p>
                            </div>
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {guests?.length > 0 ? (
                          guests.map((guest, i) => (
                            <tr key={i}>
                              <td className=" text-capitalize">
                                {guest?.name}
                              </td>
                              <td>{guest.mobile_number}</td>
                              <td>{moment(guest?.created_at).format("DD/MM/YYYY hh:mm A")}</td>
                              <td>{moment(guest?.scheduler_date).format("DD/MM/YYYY hh:mm A")}</td>
                              <td>{guest?.status}</td>
                              <td>{guest?.failed_reason}</td>
                                {guest.credits ? (
                                  <td>{guest.credits}</td>
                                ) : (
                                  <td>0</td>
                                )}
                              <td>
                                {moment(
                                  parseInt(guest.whatsapp_deliver) * 1000
                                ).format("DD/MM/YYYY hh:mm A")}
                              </td>
                              <td>
                                {moment(
                                  parseInt(guest.whatsapp_seen) * 1000
                                ).format("DD/MM/YYYY hh:mm A")}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="4"
                              className="text-center border-bottom-0"
                            >
                              <p className="mt-5 mb-0 no-data text-capitalize">
                                No data available
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {total > limit && (
                    <div className="pt-4">
                      <PaginationComponent
                        totalItems={total}
                        itemsPerPage={limit}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
