import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommonLayout from "../../../layouts/master/index";
import {
  ArrowLeft,
  UserSquare,
  EnvelopeSimple,
  Phone,
  Global,
  BuildingOffice,
  Browser,
  MapPin,
  StackPlus,
  Stack,
} from "../../../../constant/icons/icons";

export default function ResellerDetail() {
  return (
    <>
      <CommonLayout>
        <div className=""></div>
        <Container fluid className="p-6">
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className="d-flex align-items-center justify-content-between gap-3 border-bottom pb-4 mb-4">
                <div className="d-flex align-items-center gap-2  ">
                  <Link
                    to="/reseller"
                    className="d-flex align-items-center justify-content-center back-arrow"
                  >
                    <ArrowLeft
                      size={24}
                      color="#212b36"
                      weight="bold"
                      className="cursor-pointer"
                    />
                  </Link>

                  <h3 className="mb-0 fw-semibold pg-title fw-medium">
                    Reseller Details
                  </h3>
                </div>
                <div className="d-flex align-items-center pg-header-link">
                  <Link to="/reseller" className="pg-link-deactive">
                    Reseller
                  </Link>
                  <p className="mb-0">/</p>
                  <Link to="/reseller/reseller-detail">Reseller Detail</Link>
                </div>
              </div>
            </Col>
            <Col xl={12} lg={12} md={12} xs={12} className="mt-4">
              <Row className="g-4">
                <Col xl={4} lg={4} md={4} xs={4}>
                  <Row className="gy-4">
                    <Col xl={12} lg={12} md={12} xs={12}>
                      <div className="bg-white rounded-3 p-4">
                        <div>
                          <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                            <span className="rounded-circle"></span>
                            <p className="mb-0">Personal Details</p>
                          </div>
                          <div className="d-flex flex-column mt-4 reseller-de-card">
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <UserSquare
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">Name : </p>
                              </div>
                              <p className="mb-0 text-capitalize">
                                Kasvala Mayank Pareshbhai
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <EnvelopeSimple
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">
                                  E-mail :{" "}
                                </p>
                              </div>
                              <p className="mb-0 text-capitalize">reseller 1</p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <Phone
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">
                                  Mobile No. :
                                </p>
                              </div>
                              <p className="mb-0 text-capitalize">
                                Mp.kasavala13@gmail.com
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <Global
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">
                                  Country Name :
                                </p>
                              </div>
                              <p className="mb-0 text-capitalize">India</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} xs={12}>
                      <div className="bg-white rounded-3 p-4">
                        <div>
                          <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                            <span className="rounded-circle"></span>
                            <p className="mb-0">Company Details</p>
                          </div>
                          <div className="d-flex flex-column mt-4 reseller-de-card">
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <BuildingOffice
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">
                                  Company Name :
                                </p>
                              </div>
                              <p className="mb-0 text-capitalize">
                                DCPL Technology Pvt Ltd.
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <Browser
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">
                                  Website :
                                </p>
                              </div>
                              <p className="mb-0 text-capitalize">
                                dcpltechnology.com
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <MapPin
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">
                                  Address :
                                </p>
                              </div>
                              <p className="mb-0 text-capitalize">
                                Varachha, Surat, Gujarat, India
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} xs={12}>
                      <div className="bg-white rounded-3 p-4">
                        <div>
                          <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                            <span className="rounded-circle"></span>
                            <p className="mb-0">Credits Details</p>
                          </div>
                          <div className="d-flex flex-column mt-4 reseller-de-card">
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <StackPlus
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">
                                  Total Credits :
                                </p>
                              </div>
                              <p className="mb-0 text-capitalize">188516085</p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center re-title ">
                                <Stack
                                  size={20}
                                  color="#212b36"
                                  weight="regular"
                                />
                                <p className="mb-0 text-capitalize">
                                  Available Credits :
                                </p>
                              </div>
                              <p className="mb-0 text-capitalize">792403709</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={8} lg={8} md={8} xs={8}>
                  <div className="bg-white rounded-3 p-4"></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </CommonLayout>
    </>
  );
}
