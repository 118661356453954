import React, { useCallback, useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Toast,
  ToastContainer,
  InputGroup,
} from "react-bootstrap";
import {
  addCreditRequestAction,
} from "../../../store/resellerSlice";
import { Formik, Form, Field } from "formik";
// import { creditSchema } from "../../../schema/validationSchemas";
import { CurrencyInr, X, CheckCircle } from "../../../constant/icons/icons";

export default function Edit ({ show, handleModalClose, requestedCreditId, handleClose })  {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formRequestedCreditValid, setFormRequestedValid] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const initialCreditState = useMemo(() => ({
    credits: "",
    price_per_credits: "",
    price: "",
  }), []);

  const submitFormRef = useRef(null);

  const [requestedCredit, setRequestedCredit] = useState({
    credits:"",
  });

  const requestedCreditDetail = useSelector(( state ) =>
    state.reseller?.requestedCredits?.data?.find(
      (o) => o.id === requestedCreditId
    )
  );

  useEffect(() => {
    if (show && requestedCreditDetail) {
      setRequestedCredit({
        credits: requestedCreditDetail.credits || "",
        price_per_credits: requestedCreditDetail.price_per_credits || "",
        price: requestedCreditDetail.price || "",
      });
    } else if (!requestedCreditDetail) {
      setRequestedCredit(initialCreditState);
    }
  }, [show, requestedCreditDetail, initialCreditState]);
  
  // const getCreditRequest = useCallback(() => {
  //   Promise.all([
  //       dispatch(
  //         fetchResellerCreditRequest({
  //           id:12
  //       })),
  //   ])
  // }, [
  //   dispatch,
  // ]);

  const handleSubmit = useCallback(
    (accept, values, { resetForm, setErrors } ) => {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("credit_request_id", requestedCreditId);
      formData.append("credits", values?.credits);
      formData.append("credit_rate", values?.price_per_credits || 0);
      formData.append("price", values?.price);
      formData.append("action", accept);
      formData.append("reseller_id", requestedCreditDetail.reseller_id);
      formData.append("company_id", requestedCreditDetail.company_id);
      formData.append("_method", "POST");

      dispatch(addCreditRequestAction(formData))
        .then((res) => {
          if (res.payload && res.payload.success) {
            handleModalClose();  
            handleClose();
            setSnackbarMessage(res.payload.message);
            setSnackbarVariant("success");
            setShowSnackbar(true);
            resetForm();
          } else {
            setSnackbarMessage(res.payload.message);
            setErrors(res.payload.data);
            setSnackbarVariant("danger");
            setShowSnackbar(true);
          }
        })
        .catch((error) => {
          setSnackbarMessage("Failed to add credit: " + error.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [dispatch, handleModalClose, requestedCreditId, requestedCreditDetail, handleClose]
  );

  const validateForm = (values) => {
    const errors = {};
    const creditsValue = parseFloat(values.credits);  // Convert credits to a number
    const pricePerCredits = parseFloat(values.price_per_credits);  // Ensure price is a number
  
    if (!values.credits) {
      errors.credits = 'Credits are required';
    } else if (isNaN(creditsValue)) {
      errors.credits = 'Credits must be a number';  // Check if it's a valid number
    } else if (creditsValue < 1) {
      errors.credits = 'Credits must be at least 1';
    }
  
    if (!values.price_per_credits) {
      errors.price_per_credits = 'Price per credit rate is required';
    } else if (isNaN(pricePerCredits)) { 
      errors.price_per_credits = 'Price per credit rate must be a number';
    } else if (pricePerCredits < 0.1) {
      errors.price_per_credits = 'Price per credit must be greater than 0.1';
    } else if (pricePerCredits > 10000000) {
      errors.price_per_credits = 'Price per credit must be less than 10000000';
    }
  
    const isRequestedCreditValid = !Object.keys(errors).length;
  
    if (formRequestedCreditValid !== isRequestedCreditValid) {
      setFormRequestedValid(isRequestedCreditValid);
    }
  
    return errors;
  }
  

  return (
    <>
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal
        show={show}
        // onHide={handleClose}
        className="custom-backdrop"
        dialogClassName="modal-dialog-scrollable add-reseller-popup"
        centered
      >
        <Modal.Header className="add-reseller-header d-inline-block p-0  border-bottom-0">
          <div className="d-flex align-items-center justify-content-between gap-3 br-bottom py-3 px-4">
            <h5 className="fw-semibold mb-0">Add Credit</h5>
            <div className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center x-arrow">
              <X size={18} color="" weight="bold" onClick={handleClose} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="py-4">
          <Formik
            innerRef={submitFormRef}
            initialValues={requestedCredit}
            // validationSchema={creditSchema}
            validate={validateForm}            
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, errors, touched, submitForm }) => {
              submitFormRef.current = submitForm;
              return (
                <Form encType="multipart/form-data">
                    {/* <div className="d-flex align-items-center gap-2 mb-3">
                      <span style={{color:"#212B365"}}><b>Available Credits: </b></span>
                      <p className="mb-0">
                        {resellerCreditDetail.available_credits}
                      </p>
                    </div> */}
                  <div className="d-flex flex-column gap-3 add-credit-content">
                    <div>
                      <label className="form-label ms-1">Credits</label>
                      <Field
                        name="credits"
                        type="number"
                        placeholder="Enter Credits"
                        className={`form-control shadow-none ${
                          errors.credits && touched.credits ? "is-invalid" : ""
                        }`}
                        min="0"
                        onChange={(e) => {
                          setFieldValue("credits", e.target.value);
                          if (values.price_per_credits) {
                            setFieldValue(
                              "price",
                              e.target.value * values.price_per_credits
                            );
                          }
                        }}
                      />
                      {errors.credits && touched.credits && (
                        <div className="invalid-feedback ms-1 d-block">
                          {errors.credits}
                        </div>
                      )}
                    </div>

                    <div>
                      <label className="form-label ms-1">
                        Price per credit
                      </label>
                      <InputGroup>
                        {/* <InputGroup.Text>₹</InputGroup.Text> */}
                        <InputGroup.Text>
                          <CurrencyInr
                            size={16}
                            color="#637381"
                            weight="bold"
                          />
                        </InputGroup.Text>
                        <Field
                          name="price_per_credits"
                          type="number"
                          step="0.01"
                          placeholder="Enter Price Per Credit"
                          className={`form-control shadow-none ${
                            errors.price_per_credits && touched.price_per_credits ? "is-invalid" : ""
                          }`}
                          min="0"
                          onChange={(e) => {
                            // Convert string to float and update Formik's state
                            const convertedValue = parseFloat(e.target.value);
                            setFieldValue("price_per_credits", convertedValue);
                            // Update the total price calculation
                            if (values.credits) {
                              setFieldValue(
                                "price",
                                convertedValue * values.credits
                              );
                            }
                          }}
                        />
                      </InputGroup>

                      {errors.price_per_credits &&
                        touched.price_per_credits && (
                          <div className="invalid-feedback ms-1 d-block">
                            {errors.price_per_credits}
                          </div>
                        )}
                    </div>

                    <div className="d-flex align-items-center gap-2 credit-visible">
                      <span><strong>Total Credit Price: </strong></span>
                      <p className="mb-0">
                        {values.credits && values.price_per_credits
                          ? "₹" +
                            (values.credits * values.price_per_credits).toFixed(
                              2
                            )
                          : "₹0"}
                      </p>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center  py-2 px-4 add-reseller-footer">
          {/* <Button
            variant="link"
            className="close-btn fw-medium m-0"
            onClick={handleClose}
          >
            Cancel
          </Button> */}
        <Button
            type="button"
            className="reject-btn fw-medium m-0"
            disabled={formRequestedCreditValid || isSubmitting}
            onClick={() => {
              if (submitFormRef.current) {
                const { values, resetForm, setErrors } = submitFormRef.current;
                handleSubmit(false, values, { resetForm, setErrors });
              }
            }}
          >
            {isSubmitting ? "Rejecting..." : "Reject"}
          </Button>
          <Button
              type="button"
              className="next-btn fw-medium m-0"
              disabled={!formRequestedCreditValid || isSubmitting} 
              onClick={() => {
                if (submitFormRef.current) {
                  const { values, resetForm, setErrors } = submitFormRef.current;
                  handleSubmit(true, values, { resetForm, setErrors });
                }
              }}
            >
              {isSubmitting ? "Accepting..." : "Accept"}
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
 


