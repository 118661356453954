import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import AdminLogin from "./container/pages/auth/Login";
import Dashboard from "./container/pages/dashboard/Dashboard";
import { AuthProvider } from "./context/AuthContext";
import Reseller from "./container/pages/reseller/Reseller";
import PageNotFound from "./container/pages/pageNotFound/Error";
import Profile from "./container/pages/profile/ProfileDetail";
import ResellerDetail from "./container/pages/reseller/reseller-detail/[slug]";
import OrganizationType from "./container/pages/organization_type/OrganizationType";
import { LoaderProvider } from "./context/LoaderContext";
import Loader from "./container/pages/reseller/Loader";
import Setting from "./container/pages/setting/Setting";
import RequestedCredits from "./container/pages/requestedCredits/RequestedCredits";

import ProtectedLayout from "./components/ProtectedLayout";
import Inquiry from "./container/pages/inquiry/Inquiry";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <LoaderProvider>
          <Loader />
          <Router>
            <Routes>
              <Route exact path="/" element={<AdminLogin />} />
              <Route path="/" element={<ProtectedLayout />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/reseller" element={<Reseller />} />
                <Route exact path="/organization-type" element={<OrganizationType />} />
                <Route exact path="/my-account" element={<Profile />} />
                <Route exact path="/setting" element={<Setting />} />
                <Route exact path="/requested-credits" element={<RequestedCredits />} />
                <Route exact path="/inquiries" element={<Inquiry />} />
                <Route
                  exact
                  path="/reseller/reseller-detail"
                  element={<ResellerDetail />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </Router>
        </LoaderProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
