import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { CornersIn, ArrowsInSimple } from "../../../../constant/icons/icons";

import NavbarTopMenu from "./NavbarTopMenu";

const NavbarTop = (props) => {
  const [change, setChange] = useState(false);

  const handlechange = () => {
    setChange(!change);
    props.data.SidebarToggleMenu(!props.data.showMenu);
  };

  return (
    <Navbar
      expanded="lg"
      className={`navbar-classic navbar navbar-expand-lg  position-fixed top-0 start-0 end-0 z-2 ${
        change ? "cu-nav-effectend" : "cu-nav-effectstart"
      }`}
    >
      <div className="d-flex justify-content-between gap-3 w-100">
        <div className="d-flex align-items-center">
          <Link
            to="#"
            id="nav-toggle"
            className="nav-icon me-2 icon-xs"
            onClick={() => handlechange()}
          >
            {change ? (
              <ArrowsInSimple size={24} color="#747B84" weight="regular" />
            ) : (
              <CornersIn size={24} color="#747B84" weight="regular" />
            )}
          </Link>
        </div>
        <Nav className="navbar-right-wrap d-flex nav-top-wrap">
          <NavbarTopMenu />
        </Nav>
      </div>
    </Navbar>
  );
};

export default NavbarTop;
