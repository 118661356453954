import React, { useEffect, useState, useCallback } from "react";
import { Col, 
  // Dropdown, 
  Modal, 
  Row, 
  // ButtonGroup, 
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerDetail,
  fetchCustomerCredits,
  fetchCustomerEvents,
  fetchCustomerCreditDetail,
  fetchCustomerCreditLogBooks
} from "../../../store/resellerSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PaginationComponent from "../pagination/pagination";
import ClientDetailModal from "./ClientDetail";
import { createSelector } from "reselect";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";

import {
  ArrowLeft,
  UserSquare,
  EnvelopeSimple,
  Phone,
  Global,
  User,
  City,
  StackPlus,
  Stack,
  ArrowUp,
  ArrowDown,
  // DotsThreeVertical,
  // Eye,
  MagnifyingGlass,
  CalendarPlus,
  CalendarCheck,
  UsersThree,
  ClockCounterClockwise,
  Cardholder,
  MoneyWavy,
  StackMinus,
  Coins, Rupee, CreditIcon, CalendarIcon
} from "../../../constant/icons/icons";

export default function CustomerDetailModal({
  show,
  customer,
  handleClose,
  handleResellerClose,
  resellerDetails,
}) {
  const { setLoading } = useLoader();
  const limit = 10;
  const dispatch = useDispatch();
  // const countries = useSelector((state) => state.reseller.countries);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentCreditPage, setCurrentCreditPage] = useState(0);
  const [currentCreditHistoryPage, setCurrentCreditHistoryPage] = useState(0);
  const customerDetails = useSelector(
    (state) => state.reseller.currentCustomer
  );
  const customerCreditDetail = useSelector(
    (state) => state.reseller.currentCustomerCreditDetail
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null);
  const [showClientDetailModal, setShowClientDetailModal] = useState(false);
  const [sortClientKey, setSortClientKey] = useState("");
  const [sortClientOrder, setSortClientOrder] = useState("asc");
  const [sortCreditKey, setSortCreditKey] = useState("id");
  const [sortCreditOrder, setSortCreditOrder] = useState("desc");
  const [activeTab, setActiveTab] = useState("clientList");
  // const [openDropdown, setOpenDropdown] = useState({ id: null, type: null });

  // const [searchLoading, setSearchLoading] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(29, "days"),
    endDate: moment(),
  });

  const selectClientState = (state) => state.reseller.customerEvents;
  const selectCreditState = (state) => state.reseller.customerCredits;
  const selectCreditLogState = (state) => state.reseller.customerCreditLogBooks;

  const selectClientData = createSelector(
    [selectClientState],
    (customerEvents) => {
      return {
        total: customerEvents.total,
        events: customerEvents.data,
      };
    }
  );

  const selectCreditStates = createSelector(
    [selectCreditState],
    (customerCredits) => {
      return {
        totalCredit: customerCredits.total,
        credits: customerCredits.data,
      };
    }
  );

  const selectCreditLogStates = createSelector(
    [selectCreditLogState],
    (creditLogBooks) => {
      return {
        creditLogBooksData: creditLogBooks.data,
        creditLogBooks: creditLogBooks,
      };
    }
  );

  const { total, events } = useSelector(selectClientData);
  const { totalCredit, credits } = useSelector(selectCreditStates);
  const { creditLogBooksData, creditLogBooks } = useSelector(
    selectCreditLogStates
  );
  
  // const firstRender = useRef(true);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     dispatch(fetchCustomerCreditDetail(customer.id));
  //     dispatch(fetchCustomerDetail(customer.id));
  //     dispatch(
  //       fetchCustomerCredits({
  //         id: customer.id,
  //         offset: currentPage,
  //         limit,
  //         search: searchTerm,
  //         sort_by_key: sortKey,
  //         sort_by_value: sortOrder
  //       })
  //     );
  //     dispatch(
  //       fetchCustomerEvents({
  //         id: customer.id,
  //         offset: currentPage,
  //         limit,
  //         search: searchTerm,
  //         sort_by_key: sortKey,
  //         sort_by_value: sortOrder
  //       })
  //     );
  //   }
  // }, [dispatch, customer, id, currentPage, limit, searchTerm, sortKey, sortOrder ]);

  const getEventsList = useCallback(() => {
    // if (searchTerm) {
    //   setSearchLoading(false);
    // } else {
    //   setLoading(true);
    // }
    setLoading(true);
    Promise.all([
      dispatch(fetchCustomerCreditDetail(customer.id)),
      dispatch(fetchCustomerDetail(customer.id)),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, customer, setLoading]);

  useEffect(() => {
    getEventsList();
  }, [getEventsList]);

  // const getCountryName = (countryId) => {
  //   const country = countries.find((c) => c.id === countryId);
  //   return country ? country.name : "Not Found";
  // };

  // const handleDropdownToggle = (id, type) => {
  //   if (openDropdown.id === id && openDropdown.type === type) {
  //     setOpenDropdown({ id: null, type: null });
  //   } else {
  //     setOpenDropdown({ id: id, type: type });
  //   }
  // };

  // const ActionMenu = ({ event, isOpen, toggleMenu }) => {
  //   return (
  //     <Dropdown
  //       drop="up"
  //       as={ButtonGroup}
  //       show={isOpen}
  //       className="action-dropdown w-100 d-inline-block"
  //       onToggle={() => handleDropdownToggle(event.id, "action")}
  //     >
  //       <Dropdown.Toggle
  //         id={`dropdown-split-basic-${event.id}`}
  //         className="p-0"
  //         variant="link"
  //         as={CustomToggle}
  //         onClick={(e) => {
  //           e.preventDefault();
  //           e.stopPropagation();
  //           toggleMenu(event.id);
  //         }}
  //       >
  //         <DotsThreeVertical size={17} color="#637381" weight="bold" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"} className="action-dr-menu z-1">
  //         <Dropdown.Item
  //           eventKey="1"
  //           className="d-flex align-items-center dr-item item-green"
  //           onClick={() => {
  //             handleRowClick(event);
  //             toggleMenu(null);
  //           }}
  //         >
  //           <span>
  //             <Eye size={15} color="#637381" weight="regular" />
  //           </span>
  //           <p className="mb-0">View</p>
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleCreditPageChange = (newPageNumber) => {
    setCurrentCreditPage(newPageNumber - 1);
  };

  const handleCreditHistoryPageChange = (newPageNumber) => {
    setCurrentCreditHistoryPage(newPageNumber - 1);
  };

  const handleRowClick = (event) => {
    setCurrentPage(0);
    setCurrentCreditPage(0);
    setCurrentCreditHistoryPage(0);
    setSearchTerm("");
    setCurrentEvent(event);
    setShowClientDetailModal(true);
    // setOpenDropdown({ id: null, type: null });
  };

  const handleClientSort = (key) => {
    const isAsc = sortClientKey === key && sortClientOrder === "asc";
    setSortClientKey(key);
    setSortClientOrder(isAsc ? "desc" : "asc");
  };

  const handleCreditSort = (key) => {
    const isAsc = sortCreditKey === key && sortCreditOrder === "asc";
    setSortCreditKey(key);
    setSortCreditOrder(isAsc ? "desc" : "asc");
  };

  const renderSortClientIcons = (key) => {
    const isActive = sortClientKey === key;
    return (
      <>
        {/* <ArrowUp
          size={16}
          onClick={() => handleClientSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortClientOrder === "asc" ? "black" : "#ccc",
          }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleClientSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortClientOrder === "desc" ? "black" : "#ccc",
          }}
        /> */}

        <ArrowUp
          size={14}
          color={isActive && sortClientOrder === "asc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleClientSort(key, "asc")}
        />

        <ArrowDown
          size={14}
          color={isActive && sortClientOrder === "desc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleClientSort(key, "desc")}
        />
      </>
    );
  };

  const renderSortCreditIcons = (key) => {
    const isActive = sortClientKey === key;
    return (
      <>
        {/* <ArrowUp
          size={16}
          onClick={() => handleCreditSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortCreditOrder === "asc" ? "black" : "#ccc",
          }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleCreditSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortCreditOrder === "desc" ? "black" : "#ccc",
          }}
        /> */}

        <ArrowUp
          size={14}
          color={isActive && sortCreditOrder === "asc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleCreditSort(key, "asc")}
        />

        <ArrowDown
          size={14}
          color={isActive && sortCreditOrder === "desc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleCreditSort(key, "desc")}
        />
      </>
    );
  };

  // const debounce = (func, wait) => {
  //   let timeout;
  //   return function executedFunction(...args) {
  //     const later = () => {
  //       clearTimeout(timeout);
  //       func(...args);
  //     };
  //     clearTimeout(timeout);
  //     timeout = setTimeout(later, wait);
  //   };
  // };

  // const handleSearch = ()=>(debounce((search) => {
  //   setSearchTerm(search);
  //   setCurrentPage(0);
  //   setSearchLoading(true);
  // }, 500), []);

  const handleCloseModal = () => {
    setSearchTerm("");
    setCurrentPage(0);
    setCurrentCreditPage(0);
    setCurrentCreditHistoryPage(0);
    handleClose();
    setLoading(false);
    setDateRange({
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
    });
  };

  const handleEvent = (event, picker) => {
    setDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };

  const fetchClientDetailsTab = useCallback(() => {
    setLoading(true);
    setDateRange({
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
    });
    dispatch(
      fetchCustomerEvents({
        id: customer.id,
        offset: currentPage,
        limit,
        search: searchTerm,
        sort_by_key: sortClientKey,
        sort_by_value: sortClientOrder,
      })
    ).finally(() => {
      setLoading(false);
    });
  }, [
    dispatch,
    customer.id,
    currentPage,
    limit,
    searchTerm,
    sortClientKey,
    sortClientOrder,
    setLoading,
  ]);

  const fetchCreditDetailsTab = useCallback(() => {
    setLoading(true);
    setDateRange({
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
    });
    dispatch(
      fetchCustomerCredits({
        id: customer.id,
        offset: currentCreditPage,
        limit,
        sort_by_key: sortCreditKey,
        sort_by_value: sortCreditOrder,
      })
    ).finally(() => {
      setLoading(false);
    });
  }, [
    dispatch,
    customer.id,
    currentCreditPage,
    limit,
    sortCreditKey,
    sortCreditOrder,
    setLoading,
  ]);

  const fetchCreditHistoryTab = useCallback(() => {
    setLoading(true);

    dispatch(
      fetchCustomerCreditLogBooks({
        customer_id: customer.id,
        offset: currentCreditHistoryPage,
        start_date: dateRange.startDate.format("YYYY/MM/DD"),
        end_date: dateRange.endDate.format("YYYY/MM/DD"),
      })
    ).finally(() => {
      setLoading(false);
    });
  }, [dispatch, customer.id, dateRange, setLoading, currentCreditHistoryPage]);

  useEffect(() => {
    if (activeTab === "rechargeCreditHistory") {
      fetchCreditDetailsTab();
    }
  }, [fetchCreditDetailsTab, activeTab]);

  useEffect(() => {
    if (activeTab === "creditHistory") {
      fetchCreditHistoryTab();
    }
  }, [fetchCreditHistoryTab, activeTab]);

  useEffect(() => {
    fetchClientDetailsTab();
  }, [fetchClientDetailsTab]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
    switch (key) {
      case "clientList":
        setSearchTerm("");
        setCurrentPage(0);
        setCurrentCreditPage(0);
        setCurrentCreditHistoryPage(0);
        fetchClientDetailsTab();
        break;
      case "rechargeCreditHistory":
        setCurrentCreditHistoryPage(0);
        setCurrentCreditPage(0);
        fetchCreditDetailsTab();
        break;
      case "creditHistory":
        setCurrentCreditPage(0);
        setCurrentCreditHistoryPage(0);
        fetchCreditHistoryTab();
        break;

      default:
        break;
    }
  };

  const handleCloseCustomerModal = () => {
    handleCloseModal();
  };

  const handleCloseResellerModal = () => {
    handleCloseModal();
    handleResellerClose();
  };
  
  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        fullscreen={true}
        dialogClassName=""
        contentClassName="p-6 bg-light"
      >
        <Modal.Header className="p-0 border-0  d-inline-block">
          <div className="d-flex align-items-center justify-content-between gap-3 border-bottom pb-4">
            <div className="d-flex align-items-center gap-2 primary-pg-title ">
              <Link
                to=""
                className="d-flex align-items-center justify-content-center rounded-circle back-arrow"
                onClick={handleCloseModal}
              >
                <ArrowLeft
                  size={24}
                  color="#624BFF"
                  weight="bold"
                  className="cursor-pointer"
                />
              </Link>

              <h3 className="mb-0 fw-semibold pg-title fw-medium">
                Organization Details
              </h3>
            </div>
            <div className="d-flex align-items-center pg-header-link">
              <Link
                to=""
                className="pg-link-deactive"
                onClick={handleCloseResellerModal}
              >
                Reseller
              </Link>
              <p className="mb-0">/</p>
              <Link
                to=""
                className="pg-link-deactive text-capitalize"
                onClick={handleCloseCustomerModal}
              >
                {resellerDetails.name}
              </Link>
              <p className="mb-0">/</p>
              <Link to="#" className="text-capitalize">
                {customerDetails.name}
              </Link>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-5 mt-0">
            <Col xl={4} lg={12} md={12} xs={12}>
              <Row className="gy-4">
                <Col xl={12} lg={6} md={12} xs={12}>
                  <div className="bg-white rounded-3 p-4 h-100">
                    <div>
                      <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                        <span className="rounded-circle"></span>
                        <p className="mb-0">Organization Information</p>
                      </div>

                      <div className="d-flex flex-column mt-3 reseller-de-card">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <UserSquare
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Organization Name :{" "}
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerDetails.organization_name}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <EnvelopeSimple
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              E-mail :{" "}
                            </p>
                          </div>
                          <p className="mb-0">{customerDetails.email}</p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Phone size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Mobile No. :
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerDetails?.mobile_number}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Global
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              State:
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerDetails.state?.name}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <City
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              City:
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerDetails.city?.name}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <CalendarPlus
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Created on :
                            </p>
                          </div>
                          <p className="mb-0 text-capitalize">
                            {moment(customerDetails.created_at).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <CalendarCheck
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Updated on :
                            </p>
                          </div>
                          <p className="mb-0 text-capitalize">
                            {moment(customerDetails.updated_at).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={12} lg={6} md={12} xs={12}>
                  <div className="bg-white rounded-3 p-4 h-100">
                    <div>
                      <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                        <span className="rounded-circle"></span>
                        <p className="mb-0">User Details</p>
                      </div>

                      <div className="d-flex flex-column mt-3 reseller-de-card">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <UserSquare
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Person Name :{" "}
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerDetails.name}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <User size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Login ID :
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerDetails?.username}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={12} lg={6} md={12} xs={12}>
                  <div className="bg-white rounded-3 p-4 h-100">
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                          <span className="rounded-circle"></span>
                          <p className="mb-0">Credits Details</p>
                        </div>
                        {/* <Button variant="primary" onClick={handleShow}>
                          Add Credit
                        </Button> */}
                      </div>

                      <div className="d-flex flex-column mt-3 reseller-de-card">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <StackPlus
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Total Credits :
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerCreditDetail?.total_credits}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Stack size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Available Credits :
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerCreditDetail?.available_credits}
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Stack size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Reserved Credits :
                            </p>
                          </div>
                          <p className="mb-0">
                            {customerCreditDetail?.reserve_credits}
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Rupee size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Total Recharge Amount :
                            </p>
                          </div>
                          <p className="mb-0">
                          ₹{customerCreditDetail?.total_recharge_amount}
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <CreditIcon size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Last Credit alloted :
                            </p>
                          </div>
                          <p className="mb-0">
                            {
                              customerCreditDetail?.last_recharge_history
                                ?.credits ? (
                                  <td>{customerCreditDetail?.last_recharge_history?.credits}</td>
                                ):(
                                  <td>-</td>
                                )
                            }
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <CalendarIcon size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Last Recharge Date :
                            </p>
                          </div>
                          <p className="mb-0 text-capitalize">
                            {
                            customerCreditDetail?.last_recharge_history
                            ?.created_at ? (
                              moment(
                                customerCreditDetail?.last_recharge_history
                                  ?.created_at
                              ).format("DD/MM/YYYY hh:mm A")
                            ) : "-"}                          
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xl={8} lg={12} md={12} xs={12}>
              <div>
                <div className="position-relative overflow-hidden rounded-2 bg-white tablecontainer table-tabs">
                  <Tabs
                    defaultActiveKey="clientList"
                    className="pb-0 table-tabs-items"
                    id="uncontrolled-tab-example"
                    onSelect={handleTabSelect}
                  >
                    <Tab
                      eventKey="clientList"
                      title={
                        <>
                          <div className="d-flex align-items-center gap-2">
                            <UsersThree
                              size={22}
                              color="#637381"
                              weight="regular"
                            />
                            <p className="mb-0">Customer Events</p>
                          </div>
                        </>
                      }
                    >
                      <div className="d-flex align-items-center justify-content-between my-4 table-tit-head">
                        <p className="m-0">Customer Events</p>
                        <div className="position-relative">
                          <input
                            type="search"
                            placeholder="Search"
                            className="rounded-1"
                            value={searchTerm}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                              setCurrentPage(0);
                            }}
                          />
                          <MagnifyingGlass
                            size={18}
                            color="#757779"
                            className="position-absolute top-50 start-0 ms-4 translate-middle"
                            weight="regular"
                          />
                        </div>
                      </div>

                      <div className="table-responsive custom_table rounded-2">
                        <table className="w-100 rounded-2">
                          <thead>
                            <tr>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Name</p>
                                  <span className="d-flex align-items-center">
                                    {renderSortClientIcons("name")}
                                  </span>
                                </div>
                              </th>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Mobile Number</p>
                                  <span className="d-flex align-items-center">
                                    {renderSortClientIcons("mobile_number")}
                                  </span>
                                </div>
                              </th>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Campaigns</p>
                                </div>
                              </th>

                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Created On</p>
                                </div>
                              </th>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Connection Status</p>
                                </div>
                              </th>
                              {/* <th className="fw-medium">
                                <div className="d-flex align-items-center justify-content-center gap-th">
                                  <p className="mb-0">Action</p>
                                </div>
                              </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {events?.length > 0 ? (
                              events.map((event, i) => (
                                <tr
                                  key={i}
                                  className="cursor-pointer position-relative"
                                  onClick={() => handleRowClick(event)}
                                >
                                  <td>
                                    {event.name}
                                  </td>
                                  {event.contact_number ? (
                                    <td>
                                      {event.contact_number}
                                    </td>
                                  ) : (
                                    <td>-</td>
                                  )}
                                  <td>{event.campaigns_count}</td>
                                  <td>
                                    {moment(event.created_at).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )}
                                  </td>
                                  <td className="text-capitalize">
                                    {event.whatsapp_client.is_active ? (
                                      <div className="active-btn text-capitalize rounded-pill text-center ">
                                        <p className="mb-0">Connected</p>
                                      </div>
                                    ) : (
                                      <div className="in-active-btn text-capitalize rounded-pill text-center">
                                        <p className="mb-0">Not Connected</p>
                                      </div>
                                    )}
                                  </td>
                                  {/* <td className="text-center sticky-td">
                                    <ActionMenu
                                      event={event}
                                      isOpen={
                                        openDropdown.id === event.id &&
                                        openDropdown.type === "action"
                                      }
                                      toggleMenu={() =>
                                        handleDropdownToggle(event.id, "action")
                                      }
                                    />
                                  </td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="6"
                                  className="text-center border-bottom-0"
                                >
                                  <p className="mt-5 mb-0 no-data text-capitalize">
                                    No data available
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {total > limit && (
                        <div className="pt-4">
                          <PaginationComponent
                            totalItems={total}
                            itemsPerPage={limit}
                            currentPage={currentPage + 1}
                            onPageChange={handlePageChange}
                          />
                        </div>
                      )}
                    </Tab>
                    <Tab
                      eventKey="rechargeCreditHistory"
                      title={
                        <>
                          <div className="d-flex align-items-center gap-2">
                            <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            />
                            <p className="mb-0">Recharge History</p>
                          </div>
                        </>
                      }
                    >
                      <div className="d-flex align-items-center justify-content-between my-4 table-tit-head">
                        <p className="m-0">Recharge History</p>
                      </div>

                      <div className="table-responsive custom_table rounded-2">
                        <table className="w-100 rounded-2 overflow-hidden">
                          <thead>
                            <tr>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Purchase Date</p>
                                  <span className="d-flex align-items-center">
                                    {renderSortCreditIcons("created_at")}
                                  </span>
                                </div>
                              </th>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Credit Rate</p>
                                </div>
                              </th>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Credits</p>
                                  <span className="d-flex align-items-center">
                                    {renderSortCreditIcons("credits")}
                                  </span>
                                </div>
                              </th>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Total Price</p>
                                  <span className="d-flex align-items-center">
                                    {renderSortCreditIcons("price")}
                                  </span>
                                </div>
                              </th>
                              <th className="fw-medium">
                                <div className="d-flex align-items-center gap-th">
                                  <p className="mb-0">Status</p>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="credit_data">
                            {credits?.length > 0 ? (
                              credits.map((credit, i) => (
                                <tr key={i} className="data_row">
                                  <td>
                                    {moment(credit.created_at).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )}
                                  </td>
                                  {credit.credit_rate ? (
                                    <td>{`₹${credit.credit_rate}`}</td>
                                  ) : (
                                    <td>0.00</td>
                                  )}
                                  {credit.credits ? (
                                    <td>{credit.credits}</td>
                                  ) : (
                                    <td>0</td>
                                  )}
                                  {credit.price ? (
                                    <td>{`₹${credit.price}`}</td>
                                  ) : (
                                    <td>0.00</td>
                                  )}
                                  <td>
                                      {credit.status === "completed" ? (
                                        <div className="active-btn text-capitalize rounded-pill text-center ">
                                          <p className="mb-0">Completed</p>
                                        </div>
                                      ) : credit.status === "rejected" ? (
                                        <div className="in-active-btn text-capitalize rounded-pill text-center">
                                          <p className="mb-0">Rejected</p>
                                        </div>
                                      ) : (
                                        <div className="placed-btn text-capitalize rounded-pill text-center">
                                          <p className="mb-0">Requested</p>
                                        </div>
                                      )}
                                    </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="5"
                                  className="text-center border-bottom-0"
                                >
                                  <p className="mt-5 mb-0 no-data text-capitalize">
                                    No data available
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      {totalCredit > limit && (
                        <div className="pt-4">
                          <PaginationComponent
                            totalItems={totalCredit}
                            itemsPerPage={limit}
                            currentPage={currentCreditPage + 1}
                            onPageChange={handleCreditPageChange}
                          />
                        </div>
                      )}
                    </Tab>
                    <Tab
                      eventKey="creditHistory"
                      title={
                        <>
                          <div className="d-flex align-items-center gap-2">
                            <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            />
                            <p className="mb-0">Credit History</p>
                          </div>
                        </>
                      }
                    >
                      <div className="d-flex align-items-center justify-content-between my-4 table-tit-head">
                        <p className="m-0">Credit History</p>

                        <div className="mr-5" style={{position: "relative", left: "-1px"}} >
                        <DateRangePicker
                          initialSettings={{
                            startDate: dateRange.startDate.toDate(),
                            endDate: dateRange.endDate.toDate(),
                            ranges: {
                              Today: [moment(), moment()],
                              Yesterday: [
                                moment().subtract(1, "days"),
                                moment().subtract(1, "days"),
                              ],
                              "Last 7 Days": [
                                moment().subtract(6, "days"),
                                moment(),
                              ],
                              "Last 30 Days": [
                                moment().subtract(29, "days"),
                                moment(),
                              ],
                              "This Month": [
                                moment().startOf("month"),
                                moment().endOf("month"),
                              ],
                              "Last Month": [
                                moment().subtract(1, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                              ],
                              "Last 3 Months": [
                                moment().subtract(3, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                              ],
                              "Last 6 Months": [
                                moment().subtract(6, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                              ],
                              "This Year": [
                                moment().startOf("year"),
                                moment().endOf("year"),
                              ]
                            },
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                          }}
                          onApply={(e, _) => handleEvent(e, _)}
                        >
                          <button className="btn btn-primary">
                            {dateRange.startDate.format("DD/MM/YYYY")} -{" "}
                            {dateRange.endDate.format("DD/MM/YYYY")}
                          </button>
                        </DateRangePicker>
                        </div>
                      </div>
                      <div className="">
                      <Row className="my-5 g-4 cerdit-history-content">
                          <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                            <div className="p-4 rounded-3 cedit-h-card h-100" style={{width:"236px"}}>
                              <div className="d-flex align-items-start justify-content-between gap-3">
                                <div>
                                  <p className="mb-0 text-capitalize">
                                    Opening Balance
                                  </p>
                                  <span>
                                    {creditLogBooks.opening_balance || 0}
                                  </span>
                                </div>
                                <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                  <Coins
                                    size={20}
                                    color="#624BFF"
                                    weight="duotone"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                          <i className="fas fa-plus-circle fa-1x" style={{ color: "#624BFF", marginLeft:"78px" }}></i>
                        </Col>

                          <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                            <div className="p-4 rounded-3 cedit-h-card h-100" style={{width:"236px"}}>
                              <div className="d-flex align-items-start justify-content-between gap-3">
                                <div>
                                  <p className="text-capitalize">
                                    Purchase Credit
                                  </p>
                                  <span>
                                    {creditLogBooks.total_credits || 0}
                                  </span>
                                </div>
                                <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                  <StackPlus
                                    size={20}
                                    color="#624BFF"
                                    weight="duotone"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                          <i className="fas fa-minus-circle fa-1x" style={{ color: "#624BFF", marginLeft:"78px" }}></i>
                        </Col>

                          <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                            <div className="p-4 rounded-3 cedit-h-card h-100" style={{width:"236px"}}>
                              <div className="d-flex align-items-start justify-content-between gap-3">
                                <div>
                                  <p className="text-capitalize">
                                    Selling Credit
                                  </p>
                                  <span>
                                    {creditLogBooks.total_debits || 0}
                                  </span>
                                </div>
                                <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                  <StackMinus
                                    size={20}
                                    color="#624BFF"
                                    weight="duotone"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                            <i className="fas fa-equals fa-1x" style={{ color: "#624BFF", marginLeft:"78px" }}></i>
                          </Col>

                          <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                            <div className="p-4 rounded-3 cedit-h-card h-100" style={{width:"236px"}}>
                              <div className="d-flex align-items-start justify-content-between gap-3">
                                <div>
                                  <p className="text-capitalize">
                                    Closing Balance
                                  </p>
                                  <span>
                                    {creditLogBooks.closing_balance || 0}
                                  </span>
                                </div>
                                <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                  <MoneyWavy
                                    size={20}
                                    color="#624BFF"
                                    weight="duotone"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="table-responsive custom_table rounded-2">
                          <table className="w-100 rounded-2 overflow-hidden">
                            <thead>
                              <tr>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Date</p>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Description</p>
                                  </div>
                                </th>
                                {/* <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Rate</p>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Amount</p>
                                  </div>
                                </th> */}
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Message Credit</p>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Message Debit</p>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Balance</p>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="cu-tbody">
                              {creditLogBooksData?.length > 0 ? (
                                creditLogBooksData.map((credit, i) => (
                                  <tr key={i} className="align-middle data_row">
                                    <td>
                                      {moment(credit.created_at).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </td>
                                    {credit?.description ? (
                                      <td>{credit.description}</td>
                                    ) : (
                                      <td>{ credit.description }</td>
                                    )}
                                    {/* {credit.credit_rate ? (
                                    
                                      <td>{`₹${credit.credit_rate}`}</td>
                                    ) : (
                                      <td>-</td>
                                    )}
                                    {credit.price ? (
                                      <td>{`₹${credit.price}`}</td>
                                    ) : (
                                      <td>-</td>
                                    )} */}
                                    {credit.is_credited && credit.credits ? (
                                      <td>
                                        {credit.is_credited && credit.credits}
                                      </td>
                                    ) : (
                                      <td>0</td>
                                    )}
                                    {!credit.is_credited && credit.credits ? (
                                      <td>
                                        {!credit.is_credited && credit.credits}
                                      </td>
                                    ) : (
                                      <td>0</td>
                                    )}
                                    <td>
                                    {credit?.opening_balance
                                          // ? `${credit?.is_credited? credit.opening_balance + credit.credits : credit.opening_balance - credit.credits}`
                                          ?credit.opening_balance
                                          : "-"}
                                      </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="6"
                                    className="text-center border-bottom-0"
                                  >
                                    <p className="my-5  no-data text-capitalize">
                                      No data available
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Total</p>
                                  </div>
                                </td>
                                <td></td>
                                <td>
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">
                                      {creditLogBooks.total_credits}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">
                                      {creditLogBooks.total_debits}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">
                                      {}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        {
                            creditLogBooks?.total > limit && (
                              <div className="pt-4">
                                <PaginationComponent
                                  totalItems={creditLogBooks?.total}
                                  itemsPerPage={limit}
                                  currentPage={currentCreditHistoryPage + 1}
                                  onPageChange={handleCreditHistoryPageChange}
                                />
                              </div>
                            )
                          }
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {currentEvent && (
        <ClientDetailModal
          show={showClientDetailModal}
          handleClose={() => setShowClientDetailModal(false)}
          event={currentEvent}
          handleResellerClose={handleResellerClose}
          handleCustomerClose={handleCloseModal}
          customerDetails={customerDetails}
          resellerDetails={resellerDetails}
        />
      )}
    </>
  );
}
