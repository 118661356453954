import React, { useEffect, useState, useCallback } from "react";
import { Col, 
  // Dropdown, 
  Modal, 
  Row, 
  Tab, Tabs
  // ButtonGroup 
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientDetail,
  fetchCampaignDetails,
} from "../../../store/resellerSlice";
import PaginationComponent from "../pagination/pagination";
import CampaignDetailModal from "./CampaignDetail";
import { createSelector } from "reselect";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import {
  ArrowLeft,
  UserSquare,
  Phone,
  ArrowUp,
  ArrowDown,
  // DotsThreeVertical,
  // Eye,
  MagnifyingGlass,
  CalendarPlus,
  CalendarCheck,
  EnvelopeSimple,
  City,
} from "../../../constant/icons/icons";

export default function ClientDetailModal({ show, event, handleClose, handleResellerClose, handleCustomerClose, customerDetails, resellerDetails }) {
  const { setLoading } = useLoader();
  const limit = 10;
  const dispatch = useDispatch();
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [showGuestDetailModal, setShowGuestDetailModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [activeTab, setActiveTab] = useState("all");

  // const [openDropdown, setOpenDropdown] = useState({ id: null, type: null });

  const clientDetail = useSelector((state) => state.reseller.currentClient);
  
  const selectCampaignState = (state) => state.reseller.campaigns;

  const selectCampaignData = createSelector(
    [selectCampaignState],
    (customer) => {
      return {
        total: customer.total,
        campaigns: customer.data,
      };
    }
  );

  const { total, campaigns } = useSelector(selectCampaignData);

  // const firstRender = useRef(true);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     dispatch(fetchClientDetail(event.id));
  //     dispatch(
  //       fetchCampaignDetails({
  //         id: event.id,
  //         offset: currentPage,
  //         limit,
  //         search: searchTerm,
  //         sort_by_key: sortKey,
  //         sort_by_value: sortOrder
  //       })
  //     );
  //   }
  // }, [dispatch, event, id, currentPage, limit, searchTerm, sortKey, sortOrder ]);

  const getCampaignList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchClientDetail(event.id)),
      dispatch(
        fetchCampaignDetails({
          id: event.id,
          offset: currentPage,
          limit,
          search: searchTerm,
          sort_by_key: sortKey,
          sort_by_value: sortOrder,
          status: activeTab,
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    event,
    currentPage,
    limit,
    searchTerm,
    sortKey,
    sortOrder,
    setLoading,
    activeTab
  ]);

  useEffect(() => {
    getCampaignList();
  }, [getCampaignList]);

  const handleRowClick = (campaign) => {
    setCurrentPage(0);
    setSearchTerm("");
    setCurrentCampaign(campaign);
    setShowGuestDetailModal(true);
    // setOpenDropdown({ id: null, type: null });
  };

  // const handleDropdownToggle = (id, type) => {
  //   if (openDropdown.id === id && openDropdown.type === type) {
  //     setOpenDropdown({ id: null, type: null });
  //   } else {
  //     setOpenDropdown({ id: id, type: type });
  //   }
  // };

  // const ActionMenu = ({ campaign, isOpen, toggleMenu }) => {
  //   return (
  //     <Dropdown
  //       drop="up"
  //       as={ButtonGroup}
  //       className="action-dropdown w-100 d-inline-block"
  //       show={isOpen}
  //       onToggle={() => handleDropdownToggle(campaign.id, "action")}
  //     >
  //       <Dropdown.Toggle
  //         id={`dropdown-split-basic-${campaign.id}`}
  //         className="p-0"
  //         variant="link"
  //         onClick={(e) => {
  //           e.preventDefault();
  //           e.stopPropagation();
  //           toggleMenu(campaign.id);
  //         }}
  //       >
  //         <DotsThreeVertical size={17} color="#637381" weight="bold" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"} className="action-dr-menu">
  //         <Dropdown.Item
  //           eventKey="1"
  //           className="d-flex align-items-center dr-item item-green"
  //           onClick={() => handleRowClick(campaign)}
  //         >
  //           <span>
  //             <Eye size={15} color="#637381" weight="regular" />
  //           </span>
  //           <p className="mb-0">View</p>
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        <ArrowUp
          size={14}
          color={isActive && sortOrder === "asc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleSort(key, "asc")}
        />

        <ArrowDown
          size={14}
          color={isActive && sortOrder === "desc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleSort(key, "desc")}
        />
      </>
    );
  };

  const handleCloseModal = () => {
    setSearchTerm("");
    handleClose();
    setLoading(false);
  };

  const handleCloseCustomerModal = () => {
    handleCloseModal();
    handleCustomerClose();
  };

  const handleCloseResellerModal = () => {
    handleCloseModal();
    handleCustomerClose();
    handleResellerClose();
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);

    switch (key) {
      case "all":
        // setSearchTerm("");
        setLoading(true);
        setCurrentPage(0);
        // setCurrentCreditPage(0);
        // fetchCustomerDetailsTab();
        break;

      case "pending":
        setLoading(true);
        // setCurrentCreditPage(0);
        // fetchCreditDetailsTab();
        break;

      case "scheduled":
        setLoading(true);
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      case "drafted":
        setLoading(true);
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      case "in-process":
        setLoading(true);
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      case "completed":
        setLoading(true);
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      case "failed":
        setLoading(true);
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        fullscreen={true}
        dialogClassName=""
        contentClassName="p-6 bg-light"
      >
        <Modal.Header className="p-0 border-0  d-inline-block">
          <div className="d-flex align-items-center justify-content-between gap-3 border-bottom pb-4">
            <div className="d-flex align-items-center gap-2 primary-pg-title ">
              <Link
                to=""
                className="d-flex align-items-center justify-content-center rounded-circle back-arrow"
                onClick={handleCloseModal}
              >
                <ArrowLeft
                  size={24}
                  color="#624BFF"
                  weight="bold"
                  className="cursor-pointer"
                />
              </Link>

              <h3 className="mb-0 fw-semibold pg-title fw-medium">
                Customer Details
              </h3>
            </div>
            <div className="d-flex align-items-center pg-header-link">
              <Link
                to=""
                className="pg-link-deactive"
                onClick={handleCloseResellerModal}
              >
                Reseller
              </Link>
              <p className="mb-0">/</p>
              <Link
                to=""
                className="pg-link-deactive text-capitalize"
                onClick={handleCloseCustomerModal}
              >
                {resellerDetails.name}
              </Link>
              <p className="mb-0">/</p>
              <Link
                to=""
                className="pg-link-deactive text-capitalize"
                onClick={handleCloseModal}
              >
                {customerDetails.name}
              </Link>
              <p className="mb-0">/</p>
              <Link to="#" className="text-capitalize">
                {clientDetail.name}
              </Link>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-5 mt-0">
            <Col xl={4} lg={12} md={12} xs={12}>
              <Row className="gy-4">
                <Col xl={12} lg={12} md={12} xs={12}>
                  <div className="bg-white rounded-3 p-4 h-100">
                    <div>
                      <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                        <span className="rounded-circle"></span>
                        <p className="mb-0">Customer Information</p>
                      </div>
                      <div className="d-flex flex-column mt-3 reseller-de-card">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <UserSquare
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Name :{" "}
                            </p>
                          </div>
                          <p className="mb-0">{clientDetail.name}</p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <EnvelopeSimple
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Email :{" "}
                            </p>
                          </div>
                          <p className="mb-0">{clientDetail?.email}</p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Phone size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Mobile No. :
                            </p>
                          </div>
                          <p className="mb-0">
                            {clientDetail?.contact_number}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <City size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Address :
                            </p>
                          </div>
                          <p className="mb-0">
                            {clientDetail?.address}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <CalendarPlus
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Created on :
                            </p>
                          </div>
                          <p className="mb-0 text-capitalize">
                            {moment(clientDetail.created_at).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <CalendarCheck
                              size={20}
                              color="#212b36"
                              weight="regular"
                            />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Updated on :
                            </p>
                          </div>
                          <p className="mb-0 text-capitalize">
                            {moment(clientDetail.updated_at).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xl={12} lg={12} md={12} xs={12}>
                  <div className="bg-white rounded-3 p-4 h-100">
                    <div>
                      <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                        <span className="rounded-circle"></span>
                        <p className="mb-0">WhatsApp Details</p>
                      </div>
                      <div className="d-flex flex-column mt-3 reseller-de-card">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Phone size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Status :
                            </p>
                          </div>
                          <p
                          className={`mb-0 ${
                            event?.whatsapp_client?.is_active
                              ? "text-success"
                              : "text-danger"
                          }`}
                        > 
                          {event?.whatsapp_client?.is_active
                            ? "Connected"
                            : "Not Connected"}
                        </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Phone size={20} color="#212b36" weight="regular" />
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Mobile No. :
                            </p>
                          </div>
                          <p className="mb-0">
                          {event?.whatsapp_client?.is_active
                            ? event?.whatsapp_client?.mobile_number
                            : "-"}                          </p>
                        </div>


                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            <Phone size={20} color="#212b36" weight="regular" />
                            {event?.whatsapp_client?.is_active ? (
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Connected At :
                            </p>
                          ) : (
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Disconnected At :
                            </p>
                          )}
                          </div>
                          <p className="mb-0">
                          {event?.whatsapp_client?.last_sync_at
                            ? moment
                                .unix(event?.whatsapp_client?.last_sync_at)
                                .format("DD/MM/YYYY hh:mm A")
                            : "-"}                      
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xl={8} lg={12} md={12} xs={12}>
              <div>
                <div className="position-relative overflow-hidden rounded-2 bg-white tablecontainer">
                  <div className="d-flex align-items-center justify-content-between mb-4 table-tit-head">
                    <p className="m-0">Campaign List</p>
                    <div className="position-relative">
                      <input
                        type="search"
                        placeholder="Search"
                        className="rounded-1"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setCurrentPage(0);
                        }}
                      />
                      <MagnifyingGlass
                        size={18}
                        color="#757779"
                        className="position-absolute top-50 start-0 ms-4 translate-middle"
                        weight="regular"
                      />
                    </div>
                  </div>

                  <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-3">
                    <Tabs
                      defaultActiveKey="all"
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey="all"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <UserList
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">All</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="pending"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Pending</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="scheduled"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Scheduled</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="drafted"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Drafted</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="in-process"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">In-process</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="completed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Completed</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="failed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Failed</p>
                            </div>
                          </>
                        }
                      ></Tab>
                    </Tabs>
                  </div>

                  <div className="table-responsive custom_table rounded-2">
                    <table className="w-100 rounded-2">
                      <thead>
                        <tr>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Name</p>
                              <span className="d-flex align-items-center">
                                {renderSortIcons("name")}
                              </span>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Send Type</p>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Status</p>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Recipients</p>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Credits Used</p>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Created On</p>
                            </div>
                          </th>
                          {/* <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Recipient</p>
                            </div>
                          </th> */}
                          {/* <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Sent</p>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Delivered</p>
                            </div>
                          </th>
                          <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Seen</p>
                            </div>
                          </th> */}
                          {/* <th className="fw-medium">
                            <div className="d-flex align-items-center gap-th">
                              <p className="mb-0">Action</p>
                            </div>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {campaigns?.length > 0 ? (
                          campaigns.map((campaign, i) => (
                            <tr
                              key={i}
                              className="cursor-pointer position-relative"
                              onClick={() => handleRowClick(campaign)}
                            >
                              <td>{campaign?.name}</td>
                                <td>{campaign?.template_type === "text" ? "Only Message"
                                  : "With Invitation Card"}</td>
                                <td>{campaign?.status}</td>
                                <td>{campaign?.client_guests_not_test_count}</td>
                                <td>{campaign?.total_credits || 0}</td>
                                <td>{moment(campaign.created_at).format("DD/MM/YYYY hh:mm A")}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="7"
                              className="text-center border-bottom-0"
                            >
                              <p className="mt-5 mb-0 no-data text-capitalize">
                                No data available
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {total > limit && (
                    <div className="pt-4">
                      <PaginationComponent
                        totalItems={total}
                        itemsPerPage={limit}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {currentCampaign && (
        <CampaignDetailModal
          show={showGuestDetailModal}
          handleClose={() => setShowGuestDetailModal(false)}
          campaign={currentCampaign}
          handleOrganizationClose={handleCloseModal}
          handleResellerClose={handleResellerClose}
          handleCustomerClose={handleCustomerClose}
          customerDetails={customerDetails}
          resellerDetails={resellerDetails}
        />
      )}
    </>
  );
}
