import React, { useState } from "react";
import CommonLayout from "../../layouts/master/index";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";

export default function OrganizationType() {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentOrganizationTypeId, setCurrentOrganizationTypeId] = useState(null);
  return (
    <>
      <CommonLayout>
        <div>
          <List
            editHandler={(id) => {
              setCurrentOrganizationTypeId(id);
              setShowEditModal(true);
            }}
            handleOpenModal={() => setShowAddModal(true)}
          />
          <Add show={showAddModal} handleClose={() => setShowAddModal(false)} />
          {showEditModal && (
            <Edit
              show={showEditModal}
              handleClose={() => setShowEditModal(false)}
              organizationTypeId={currentOrganizationTypeId}
            />
          )}
        </div>
      </CommonLayout>
    </>
  );
}
