import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useLoader } from '../../../context/LoaderContext';

const Loader = () => {
    const { loading } = useLoader();

    if (!loading) return null;

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 9999999, height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.5)" }}>
            <Spinner animation="border" role="status" variant="light">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};

export default Loader;
