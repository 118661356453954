import React, { useCallback, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Button,
  Toast,
  ToastContainer,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import {
  addCreditReseller,
  fetchResellerCreditDetail,
  fetchResellerCredits,
  fetchCreditLogBooks,
  fetchCustomerCreditLogBooks
} from "../../../store/resellerSlice";
import { Formik, Form, Field } from "formik";
import { creditSchema } from "../../../schema/validationSchemas";
import { CurrencyInr, X, CheckCircle } from "../../../constant/icons/icons";

const Credit = ({ show, handleClose, companyId, resellerId, itemsPerPage }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const initialCreditState = {
    credits: "",
    price_per_credits: "",
    price: "",
  };

  const submitFormRef = useRef(null);

  const getCurrentFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const handleSubmit = useCallback(
    (values, { resetForm, setErrors }) => {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("credits", values.credits);
      formData.append("credit_rate", values.price_per_credits);
      formData.append("price", values.price);
      formData.append("company_id", companyId);
      formData.append("reseller_id", resellerId);
      formData.append("_method", "POST");

      dispatch(addCreditReseller(formData))
        .then((res) => {
          if (res.payload && res.payload.success) {
            dispatch(
              fetchResellerCredits({
                id: companyId,
                offset: 0,
                limit: itemsPerPage,
                search: "",
              })
            );
            dispatch(fetchResellerCreditDetail(resellerId));
            dispatch(
              fetchCreditLogBooks({
                reseller_id: resellerId,
                sort_by_key: "id",
                sort_by_value: "desc",
                start_date: "2024/06/01",
                end_date: getCurrentFormattedDate(),
              })
            );
            dispatch(
              fetchCustomerCreditLogBooks({
                customer_id: resellerId,
                sort_by_key: "id",
                sort_by_value: "desc",
                start_date: "2024/06/01",
                end_date: getCurrentFormattedDate(),
              })
            );
            setSnackbarMessage(res.payload.message);
            setSnackbarVariant("success");
            setShowSnackbar(true);
            resetForm();
            handleClose();
          } else {
            setSnackbarMessage(res.payload.message);
            setErrors(res.payload.data);
            setSnackbarVariant("danger");
            setShowSnackbar(true);
          }
        })
        .catch((error) => {
          setSnackbarMessage("Failed to add credit: " + error.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [companyId, resellerId, itemsPerPage, dispatch, handleClose]
  );

  return (
    <>
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal
        show={show}
        onHide={handleClose}
        className="custom-backdrop"
        dialogClassName="modal-dialog-scrollable add-reseller-popup"
        centered
      >
        <Modal.Header className="add-reseller-header d-inline-block p-0  border-bottom-0">
          <div className="d-flex align-items-center justify-content-between gap-3 br-bottom py-3 px-4">
            <h5 className="fw-semibold mb-0">Add Credit</h5>
            <div className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center x-arrow">
              <X size={18} color="" weight="bold" onClick={handleClose} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="py-4">
          <Formik
            initialValues={initialCreditState}
            validationSchema={creditSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched, submitForm }) => {
              submitFormRef.current = submitForm;
              return (
                <Form encType="multipart/form-data">
                    {/* <div className="d-flex align-items-center gap-2 mb-3">
                      <span style={{color:"#212B365"}}><b>Available Credits: </b></span>
                      <p className="mb-0">
                        {resellerCreditDetail.available_credits}
                      </p>
                    </div> */}
                  <div className="d-flex flex-column gap-3 add-credit-content">
                    <div>
                      <label className="form-label ms-1">Credits</label>
                      <Field
                        name="credits"
                        type="number"
                        placeholder="Enter Credits"
                        className={`form-control shadow-none ${
                          errors.credits && touched.credits ? "is-invalid" : ""
                        }`}
                        min="0"
                        onChange={(e) => {
                          setFieldValue("credits", e.target.value);
                          if (values.price_per_credits) {
                            setFieldValue(
                              "price",
                              e.target.value * values.price_per_credits
                            );
                          }
                        }}
                      />
                      {errors.credits && touched.credits && (
                        <div className="invalid-feedback ms-1 d-block">
                          {errors.credits}
                        </div>
                      )}
                    </div>

                    <div>
                      <label className="form-label ms-1">
                        Price per credit
                      </label>
                      <InputGroup>
                        {/* <InputGroup.Text>₹</InputGroup.Text> */}
                        <InputGroup.Text>
                          <CurrencyInr
                            size={16}
                            color="#637381"
                            weight="bold"
                          />
                        </InputGroup.Text>
                        <Field
                          name="price_per_credits"
                          type="number"
                          step="0.01"
                          placeholder="Enter Price Per Credit"
                          className={`form-control shadow-none  ${
                            errors.price_per_credits &&
                            touched.price_per_credits
                              ? "is-invalid"
                              : ""
                          }`}
                          min="0"
                          onChange={(e) => {
                            setFieldValue("price_per_credits", e.target.value);
                            if (values.credits) {
                              setFieldValue(
                                "price",
                                e.target.value * values.credits
                              );
                            }
                          }}
                        />
                      </InputGroup>

                      {errors.price_per_credits &&
                        touched.price_per_credits && (
                          <div className="invalid-feedback ms-1 d-block">
                            {errors.price_per_credits}
                          </div>
                        )}
                    </div>

                    <div className="d-flex align-items-center gap-2 credit-visible">
                      <span><strong>Total Credit Price: </strong></span>
                      <p className="mb-0">
                        {values.credits && values.price_per_credits
                          ? "₹" +
                            (values.credits * values.price_per_credits).toFixed(
                              2
                            )
                          : "₹0"}
                      </p>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center  py-2 px-4 add-reseller-footer">
          <Button
            variant="link"
            className="close-btn fw-medium m-0"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="link"
            className="next-btn fw-medium m-0"
            disabled={isSubmitting}
            onClick={() => submitFormRef.current && submitFormRef.current()}
          >
            {isSubmitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Adding...
              </>
            ) : (
              "Add"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Credit;
