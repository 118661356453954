import React from "react";
import CommonLayout from "../../../container/layouts/master/index";

const Dashboard = () => {
  return (
    <>
      <CommonLayout>
        <div className="p-4">
          <h1>Dashboard</h1>
        </div>
      </CommonLayout>
    </>
  );
};

export default Dashboard;
