  import React, { useEffect, useState, useCallback } from "react";
  import {
    Col,
    Dropdown,
    Modal,
    Button,
    Row,
    ButtonGroup,
    // OverlayTrigger,
    // Tooltip,
    Toast,
    ToastContainer,
    Badge,
  } from "react-bootstrap";
  import { Link } from "react-router-dom";
  import Tab from "react-bootstrap/Tab";
  import Tabs from "react-bootstrap/Tabs";
  import { useDispatch, useSelector } from "react-redux";
  import {
    fetchResellerDetail,
    fetchCustomerDetails,
    fetchResellerCredits,
    fetchResellerCreditDetail,
    fetchCreditLogBooks,
    deleteReseller,
    fetchResellers,
    toggleResellerActiveStatus,
  } from "../../../store/resellerSlice";
  import PaginationComponent from "../pagination/pagination";
  import CustomerDetailModal from "./CustomerDetail";
  import Credit from "./Credit";
  import { createSelector } from "reselect";
  import { useLoader } from "../../../context/LoaderContext";
  import moment from "moment";
  import "bootstrap-daterangepicker/daterangepicker.css";
  import DateRangePicker from "react-bootstrap-daterangepicker";
  import {
    ArrowLeft,
    UserSquare,
    EnvelopeSimple,
    Phone,
    User,
    City,
    Global,
    BuildingOffice,
    Browser,
    MapPin,
    StackPlus,
    Stack,
    ArrowUp,
    ArrowDown,
    DotsThreeVertical,
    // Eye,
    MagnifyingGlass,
    Coins,
    StackMinus,
    MoneyWavy,
    UserList,
    // CreditCard,
    Cardholder,
    CalendarPlus,
    CalendarCheck,
    // Plus,
    // Calendar,
    ClockCounterClockwise,
    Pencil,
    Trash,
    CheckCircle,
    Warning,
    X,
    LinkIcon,
    Rupee,
    CreditIcon,
    CalendarIcon,
    UserCheck,
  } from "../../../constant/icons/icons";

  export default function ResellerDetailModal({
    show,
    reseller,
    handleResellerDetailClose,
    editHandler,
  }) {
    const { setLoading } = useLoader();
    const limit = 9;
    const dispatch = useDispatch();
    const resellerDetails = useSelector(
      (state) => state.reseller.currentReseller
    );
    const resellerCreditDetail = useSelector(
      (state) => state.reseller.currentCreditDetail
    );

    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentCustomer, setCurrentCustomer] = useState(null);
    const [showCustomerDetailModal, setShowCustomerDetailModal] = useState(false);
    const [sortCustomerKey, setSortCustomerKey] = useState("");
    const [sortCustomerOrder, setSortCustomerOrder] = useState("asc");
    const [sortCreditKey, setSortCreditKey] = useState("id");
    const [activeTab, setActiveTab] = useState("customerList");
    const [sortCreditOrder, setSortCreditOrder] = useState("desc");
    const [currentCreditPage, setCurrentCreditPage] = useState(0);
    const [currentCreditHistoryPage, setCurrentCreditHistoryPage] = useState(0);
    // const [openDropdown, setOpenDropdown] = useState({ id: null, type: null });
    const [openResellerDetailDropdown, setOpenResellerDetailDropdown] = useState({
      id: null,
      type: null,
    });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [resellerToDelete, setResellerToDelete] = useState({});
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarVariant, setSnackbarVariant] = useState("success");
    const [showToggleConfirmation, setShowToggleConfirmation] = useState(false);
    // const [resellerToToggle, setResellerToToggle] = useState({});
    // const [toggleStatus, setToggleStatus] = useState(null);
    // const [searchLoading, setSearchLoading] = useState(false);

    const [dateRange, setDateRange] = useState({
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
    });

    const selectCustomerState = (state) => state.reseller.customer;
    const selectCreditState = (state) => state.reseller.credits;
    const selectCreditLogState = (state) => state.reseller.creditLogBooks;

    const selectCustomerData = createSelector(
      [selectCustomerState],
      (customer) => {
        return {
          total: customer.total,
          customers: customer.data,
        };
      }
    );

    const selectCreditStates = createSelector(
      [selectCreditState],
      (credits) => {
        return {
          totalCredit: credits.total,
          credits: credits.data,
        };
      }
    );

    const selectCreditLogStates = createSelector(
      [selectCreditLogState],
      (creditLogBooks) => {
        return {
          creditLogBooksData: creditLogBooks.data,
          creditLogBooks: creditLogBooks,
        };
      }
    );

    const { total, customers } = useSelector(selectCustomerData);
    const { totalCredit, credits } = useSelector(selectCreditStates);
    const { creditLogBooksData, creditLogBooks } = useSelector(
      selectCreditLogStates
    );
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => {
      setCurrentCreditPage(0);
      setCurrentCreditHistoryPage(0);
      setShowModal(false);
    };

    // useEffect(() => {
    //   if (firstRender.current) {
    //     firstRender.current = false;
    //   } else {
    //     dispatch(fetchResellerCreditDetail(reseller.id));
    //     dispatch(fetchResellerDetail(reseller.id));
    //     dispatch(
    //       fetchCustomerDetails({
    //         id: reseller.id,
    //         offset: currentPage,
    //         limit,
    //         search: searchTerm,
    //         sort_by_key: sortKey,
    //         sort_by_value: sortOrder
    //       })
    //     );
    //     dispatch(
    //       fetchResellerCredits({
    //         id: reseller.id,
    //         offset: currentPage,
    //         limit,
    //         search: searchTerm,
    //         sort_by_key: sortKey,
    //         sort_by_value: sortOrder
    //       })
    //     );
    //   }
    // }, [dispatch, reseller, id, currentPage, limit, searchTerm, sortKey, sortOrder ]);

    const getCustomerList = useCallback(async () => {
      setLoading(true);
      Promise.all([
        dispatch(fetchResellerCreditDetail(reseller.id)),
        dispatch(fetchResellerDetail(reseller.id)),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [dispatch, reseller, setLoading]);

    useEffect(() => {
      getCustomerList();
    }, [getCustomerList]);

    const promptDeleteReseller = (reseller) => {
      setResellerToDelete(reseller);
      setShowDeleteConfirmation(true);
    };

    const handleDeleteReseller = async () => {
      if (resellerToDelete) {
        try {
          await dispatch(deleteReseller(resellerToDelete.id));
          setSnackbarMessage("Reseller deleted successfully");
          setSnackbarVariant("success");
          dispatch(
            fetchResellers({
              offset: currentPage * limit,
              limit,
              search: searchTerm,
            })
          );
          handleCloseModal();
        } catch (error) {
          setSnackbarMessage("Error deleting reseller");
          setSnackbarVariant("danger");
        } finally {
          setShowSnackbar(true);
          setShowDeleteConfirmation(false);
          setResellerToDelete(null);
        }
      }
    };

    const handleDropdownToggleResellerDetail = (id, type) => {
      if (
        openResellerDetailDropdown.id === id &&
        openResellerDetailDropdown.type === type
      ) {
        // Close the currently open dropdown
        setOpenResellerDetailDropdown({ id: null, type: null });
      } else {
        // Open the new dropdown and close any others
        setOpenResellerDetailDropdown({ id: id, type: type });
      }
    };

    const ActionMenuResellerDetail = ({
      reseller,
      isResellerDetailOpen,
      toggleMenuResellerDetail,
    }) => (
      <Dropdown
        drop="up"
        as={ButtonGroup}
        className="action-dropdown w-100 d-inline-block"
        show={isResellerDetailOpen}
        onToggle={() => handleDropdownToggleResellerDetail(reseller.id, "action")}
      >
        <Dropdown.Toggle
          id={`dropdown-split-basic-${reseller.id}`}
          className="p-0"
          variant="link"
          as={CustomToggle}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleMenuResellerDetail(reseller.id);
          }}
        >
          <DotsThreeVertical size={17} color="#637381" weight="bold" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end" className="action-dr-menu z-1">
          <Dropdown.Item
            eventKey="1"
            className="d-flex align-items-center dr-item item-orange"
            onClick={(e) => {
              e.stopPropagation();
              editHandler(reseller.id);
            }}
          >
            <span>
              <Pencil size={15} color="#637381" weight="regular" />
            </span>
            <p className="mb-0 mx-2">Edit</p>
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            className="d-flex align-items-center dr-item item-red"
            onClick={(e) => {
              e.stopPropagation();
              promptDeleteReseller(reseller);
              toggleMenuResellerDetail(null); // Close the menu after action
            }}
          >
            <span>
              <Trash size={15} color="#637381" weight="regular" />
            </span>
            <p className="mb-0 mx-2">Delete</p>
          </Dropdown.Item>

          <Dropdown.Item
            className="dr-menu-item"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              promptToggleConfirmation(reseller, !reseller.is_active);
              toggleMenuResellerDetail(null);
            }}
          >
            <span>
              <UserCheck size={15} color="#637381" weight="regular" />
            </span>
            <span className="mx-2">
              Make {resellerDetails?.is_active ? "Inactive" : "Active"}
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );

    // const handleDropdownToggle = (id, type) => {
    //   if (openDropdown.id === id && openDropdown.type === type) {
    //     setOpenDropdown({ id: null, type: null });
    //   } else {
    //     setOpenDropdown({ id: id, type: type });
    //   }
    // };

    // const ActionMenu = ({ customer, isOpen, toggleMenu }) => (
    //   <Dropdown
    //     drop="up"
    //     as={ButtonGroup}
    //     className="action-dropdown w-100 d-inline-block"
    //     show={isOpen}
    //     onToggle={() => handleDropdownToggle(customer.id, "action")}
    //   >
    //     <Dropdown.Toggle
    //       id={`dropdown-split-basic-${customer.id}`}
    //       className="p-0"
    //       variant="link"
    //       as={CustomToggle}
    //       onClick={(e) => {
    //         e.preventDefault();
    //         e.stopPropagation();
    //         toggleMenu(customer.id);
    //       }}
    //     >
    //       <DotsThreeVertical size={17} color="#637381" weight="bold" />
    //     </Dropdown.Toggle>
    //     <Dropdown.Menu align="end" className="action-dr-menu z-1">
    //       <Dropdown.Item
    //         eventKey="1"
    //         className="d-flex align-items-center dr-item item-green"
    //         onClick={() => {
    //           handleRowClick(customer);
    //           toggleMenu(null);
    //         }}
    //       >
    //         <span>
    //           <Eye size={15} color="#637381" weight="regular" />
    //         </span>
    //         <p className="mb-0">View</p>
    //       </Dropdown.Item>
    //     </Dropdown.Menu>
    //   </Dropdown>
    // );

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <Link
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }}
        className="text-muted text-primary-hover"
      >
        {children}
      </Link>
    ));

    CustomToggle.displayName = "CustomToggle";

    const handlePageChange = (newPageNumber) => {
      setCurrentPage(newPageNumber - 1);
    };

    const handleCreditPageChange = (newPageNumber) => {
      setCurrentCreditPage(newPageNumber - 1);
    };

    const handleCreditHistoryPageChange = (newPageNumber) => {
      setCurrentCreditHistoryPage(newPageNumber - 1);
    };

    const handleRowClick = (customer) => {
      setCurrentPage(0);
      setCurrentCreditPage(0);
      setCurrentCreditHistoryPage(0);
      setSearchTerm("");
      setCurrentCustomer(customer);
      setShowCustomerDetailModal(true);
      // setOpenDropdown({ id: null, type: null });
    };

    const handleCustomerSort = (key) => {
      const isAsc = sortCustomerKey === key && sortCustomerOrder === "asc";

      setSortCustomerKey(key);

      setSortCustomerOrder(isAsc ? "desc" : "asc");
    };

    const handleCreditSort = (key) => {
      const isAsc = sortCreditKey === key && sortCreditOrder === "asc";

      setSortCreditKey(key);

      setSortCreditOrder(isAsc ? "desc" : "asc");
    };

    const renderSortCustomerIcons = (key) => {
      const isActive = sortCustomerKey === key;
      return (
        <>
          <ArrowUp
            size={14}
            color={isActive && sortCustomerOrder === "asc" ? "#757779" : "#ccc"}
            className="cursor-pointer"
            weight="fill"
            onClick={() => handleCustomerSort(key, "asc")}
          />

          <ArrowDown
            size={14}
            color={isActive && sortCustomerOrder === "desc" ? "#757779" : "#ccc"}
            className="cursor-pointer"
            weight="fill"
            onClick={() => handleCustomerSort(key, "desc")}
          />
        </>
      );
    };

    const renderSortCreditIcons = (key) => {
      const isActive = sortCustomerKey === key;

      return (
        <>
          <ArrowUp
            size={14}
            color={isActive && sortCreditOrder === "asc" ? "#757779" : "#ccc"}
            className="cursor-pointer"
            weight="fill"
            onClick={() => handleCreditSort(key, "asc")}
          />

          <ArrowDown
            size={14}
            color={isActive && sortCreditOrder === "desc" ? "#757779" : "#ccc"}
            className="cursor-pointer"
            weight="fill"
            onClick={() => handleCreditSort(key, "desc")}
          />
        </>
      );
    };

    // const debounce = (func, wait) => {
    //   let timeout;
    //   return function executedFunction(...args) {
    //     const later = () => {
    //       clearTimeout(timeout);
    //       func(...args);
    //     };
    //     clearTimeout(timeout);
    //     timeout = setTimeout(later, wait);
    //   };
    // };

    // const handleSearch = useCallback(debounce((search) => {
    //   setSearchTerm(search);
    //   setCurrentPage(0);
    //   setSearchLoading(true);
    // }, 500), [setSearchTerm, setCurrentPage, setSearchLoading]);

    const handleCloseModal = () => {
      setSearchTerm("");
      setCurrentPage(0);
      setCurrentCreditPage(0);
      setOpenResellerDetailDropdown(false);

      setDateRange({
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
      });

      handleResellerDetailClose();
      setLoading(false);
    };

    const handleEvent = (event, picker) => {
      setDateRange({
        startDate: picker.startDate,
        endDate: picker.endDate,
      });
    };

    const fetchCustomerDetailsTab = useCallback(() => {
      setLoading(true);
      setDateRange({
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
      });
      dispatch(
        fetchCustomerDetails({
          id: reseller.company_id,

          offset: currentPage,

          limit,

          search: searchTerm,

          sort_by_key: sortCustomerKey,

          sort_by_value: sortCustomerOrder,
        })
      ).finally(() => {
        setLoading(false);
      });
    }, [
      dispatch,
      reseller.company_id,
      currentPage,
      limit,
      searchTerm,
      sortCustomerKey,
      sortCustomerOrder,
      setLoading,
    ]);

    const fetchCreditDetailsTab = useCallback(() => {
      setLoading(true);
      setDateRange({
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
      });
      dispatch(
        fetchResellerCredits({
          id: reseller.company_id,
          offset: currentCreditPage,
          limit,
          sort_by_key: "created_at",
          sort_by_value: sortCreditOrder,
          status:"completed"
        })
      ).finally(() => {
        setLoading(false);
      });
    }, [
      dispatch,
      reseller.company_id,
      currentCreditPage,
      limit,
      sortCreditOrder,
      setLoading,
    ]);

    const fetchCreditHistoryTab = useCallback(() => {
      setLoading(true);

      dispatch(
        fetchCreditLogBooks({
          reseller_id: reseller.id,
          offset: currentCreditHistoryPage,
          limit,
          start_date: dateRange.startDate.format("YYYY/MM/DD"),

          end_date: dateRange.endDate.format("YYYY/MM/DD"),
        })
      ).finally(() => {
        setLoading(false);
      });
    }, [dispatch, reseller.id, dateRange, setLoading, limit, currentCreditHistoryPage]);

    useEffect(() => {
      if (activeTab === "rechargeCreditHistory") {
        fetchCreditDetailsTab();
      }
    }, [fetchCreditDetailsTab, activeTab]);

    useEffect(() => {
      if (activeTab === "creditHistory") {
        fetchCreditHistoryTab();
      }
    }, [fetchCreditHistoryTab, activeTab]);

    useEffect(() => {
      fetchCustomerDetailsTab();
    }, [fetchCustomerDetailsTab]);

    const handleTabSelect = (key) => {
      setActiveTab(key);

      switch (key) {
        case "customerList":
          setSearchTerm("");
          setCurrentPage(0);
          setCurrentCreditPage(0);
          setCurrentCreditHistoryPage(0);
          fetchCustomerDetailsTab();
          break;

        case "rechargeCreditHistory":
          setCurrentCreditPage(0);
          setCurrentCreditHistoryPage(0);
          fetchCreditDetailsTab();
          break;

        case "creditHistory":
          setCurrentCreditPage(0);
          setCurrentCreditHistoryPage(0);
          fetchCreditHistoryTab();
          break;

        default:
          break;
      }
    };

    const promptToggleConfirmation = (reseller, status) => {
      // setResellerToToggle(reseller);
      // setToggleStatus(status);
      setShowToggleConfirmation(true);
    };

    // const handleConfirmToggleActive = () => {
    //   if (resellerToToggle) {
    //     dispatch(
    //       toggleResellerActiveStatus({
    //         id: resellerToToggle.id,
    //         isActive: toggleStatus.toString(),
    //       })
    //     )
    //       .then(() => {
    //         console.log("Status", toggleStatus);
    //         setSnackbarMessage(
    //           `Reseller ${
    //             toggleStatus ? "activated" : "deactivated"
    //           } successfully`
    //         );
    //         setSnackbarVariant("success");
    //       })
    //       .catch((error) => {
    //         setSnackbarMessage("Failed to update reseller status");
    //         setSnackbarVariant("danger");
    //       })
    //       .finally(() => {
    //         setShowSnackbar(true);
    //         setShowToggleConfirmation(false);
    //         setResellerToToggle(null);
    //         setToggleStatus(null);
    //       });
    //   }
    // };

    const handleConfirmToggleActive = async () => {
      if (resellerDetails) {
        try {
          const value = resellerDetails.is_active;
          const response = await dispatch(
            toggleResellerActiveStatus({
              id: resellerDetails.id,
              isActive: value ? "false" : "true",
            })
          );

          if (response.payload) {
            getCustomerList();
          }
          setSnackbarMessage(
            `Reseller ${
              resellerDetails?.is_active ? "deactivated" : "activate"
            } successfully`
          );
          setSnackbarVariant("success");
        } catch (error) {
          setSnackbarMessage("Failed to update reseller status");
          setSnackbarVariant("danger");
        } finally {
          setShowSnackbar(true);
          setShowToggleConfirmation(false);
          // setResellerToToggle(null);
          // setToggleStatus(null);
        }
      }
    };
    // const StatusDropdown = ({ reseller, isOpen, toggleDropdown }) => (
    //   <Dropdown
    //     show={isOpen}
    //     onToggle={() => handleDropdownToggleResellerDetail(reseller.id, "status")}
    //   >
    //     <Dropdown.Toggle
    //       className="p-0 d-flex"
    //       variant="link"
    //       id={`dropdown-basic-${reseller.id}`}
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         toggleDropdown(reseller.id);
    //       }}
    //     >
    //       {resellerDetails?.is_active ? (
    //         <div className="d-flex align-items-center gap-1 active-btn text-capitalize rounded-pill">
    //           <p className="mb-0">Active</p>
    //         </div>
    //       ) : (
    //         <div className="d-flex align-items-center gap-1 in-active-btn text-capitalize rounded-pill">
    //           <p className="mb-0">In Active</p>
    //         </div>
    //       )}
    //     </Dropdown.Toggle>
    //     <Dropdown.Menu className="dr-menu z-1">
    //       <Dropdown.Item
    //         className="dr-menu-item"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           e.stopPropagation();
    //           promptToggleConfirmation(reseller, !reseller.is_active);
    //           toggleDropdown(null);
    //         }}
    //       >
    //         Set {resellerDetails?.is_active ? "In-Active" : "Active"}
    //       </Dropdown.Item>
    //     </Dropdown.Menu>
    //   </Dropdown>
    // );
    // const calculateBalances = (creditLogBooksData) => {
    //   let balance = 0;
    //   const updatedLogs = creditLogBooksData
    //     ?.slice()
    //     ?.reverse()
    //     .map((log) => {
    //       const credit = log.is_credited ? log.credits : 0;
    //       const debit = !log.is_credited ? log.credits : 0;
    //       balance += credit - debit;
    //       return { ...log, balance };
    //     });
    //   return updatedLogs?.reverse();
    // };

    // const creditLogsWithBalances = calculateBalances(creditLogBooksData);

    return (
      <>
        <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
          <Toast
            onClose={() => setShowSnackbar(false)}
            show={showSnackbar}
            delay={3000}
            autohide
            bg={snackbarVariant}
          >
            <Toast.Body className="d-flex align-items-center gap-2 text-white">
              <CheckCircle size={22} color="#ffffff" weight="duotone" />
              <p className="mb-0">{snackbarMessage}</p>
            </Toast.Body>
          </Toast>
        </ToastContainer>

        <Modal
          show={showToggleConfirmation}
          onHide={() => setShowToggleConfirmation(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Status Change</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to{" "}
            {resellerDetails?.is_active ? "deactivate" : "activate"}{" "}
            <b>{resellerDetails?.name}</b> ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"

              onClick={() => setShowToggleConfirmation(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmToggleActive}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          dialogClassName="modal-dialog-scrollable add-reseller-popup"
          centered
        >
          <Modal.Header className="add-reseller-header d-inline-block p-0  border-bottom-0">
            <div className="d-flex align-items-center justify-content-between gap-3 br-bottom py-3 px-4">
              <h5 className="fw-semibold mb-0">Delete Reseller</h5>
              <div className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center x-arrow">
                <X
                  size={18}
                  color=""
                  weight="bold"
                  onClick={() => setShowDeleteConfirmation(false)}
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="py-4 delete-reseller-content">
            <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
              <div className="rounded-2 d-flex align-items-center justify-content-center error-show-icon">
                <Warning size={46} color="#dc3545" weight="regular" />
              </div>
              <p className="mb-0 fw-medium text-center">
                Are you sure you want to delete <b>{resellerToDelete?.name}</b>.
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex align-items-center justify-content-center pt-2  pb-4 px-4 border-top-0 add-reseller-footer">
            <Button
              variant="link"
              className="close-btn fw-medium m-0"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              variant="link"
              className="next-btn fw-medium m-0"
              onClick={() => handleDeleteReseller()}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show}
          onHide={handleCloseModal}
          fullscreen={true}
          dialogClassName=""
          contentClassName="p-6 bg-light"
        >
          <Modal.Header className="p-0 border-0  d-inline-block">
            <div className="d-flex align-items-center justify-content-between gap-3 border-bottom pb-4">
              <div className="d-flex align-items-center gap-2 primary-pg-title ">
                <Link
                  to=""
                  className="d-flex align-items-center justify-content-center rounded-circle back-arrow"
                  onClick={handleCloseModal}
                >
                  <ArrowLeft
                    size={24}
                    color="#624BFF"
                    weight="bold"
                    className="cursor-pointer"
                  />
                </Link>

                <h3 className="mb-0 fw-semibold pg-title fw-medium">
                  Reseller Details
                </h3>
              </div>
              <div className="d-flex align-items-center pg-header-link">
                <Link
                  to=""
                  className="pg-link-deactive"
                  onClick={handleCloseModal}
                >
                  Reseller
                </Link>
                <p className="mb-0">/</p>
                <Link to="#" className="text-capitalize">
                  {resellerDetails.name}
                </Link>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row className="g-5 mt-0">
              <Col xl={4} lg={12} md={12} xs={12}>
                <Row className="gy-4">
                  <Col xl={12} lg={6} md={12} xs={12}>
                    <div className="bg-white rounded-3 p-4 h-100">
                      <div>
                        <div className="d-flex justify-content-between row">
                          <div className="d-flex align-items-center gap-2 fw-semibold sub-heading col-11">
                            <span className="rounded-circle"></span>
                            <p className="mb-0">Personal Details</p>
                          </div>
                          {/* <div> */}

                          {/* </div> */}

                          <div className="col-1">
                            <ActionMenuResellerDetail
                              reseller={reseller}
                              isResellerDetailOpen={
                                openResellerDetailDropdown.id === reseller.id &&
                                openResellerDetailDropdown.type === "action"
                              }
                              toggleMenuResellerDetail={() =>
                                handleDropdownToggleResellerDetail(
                                  reseller.id,
                                  "action"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="my-2">
                          {resellerDetails.is_active ? (
                            <Badge pill bg="success">
                              Active
                            </Badge>
                          ) : (
                            <Badge pill bg="danger">
                              Inactive
                            </Badge>
                          )}
                          {/* <StatusDropdown
                            reseller={reseller}
                            isOpen={
                              openResellerDetailDropdown.id === reseller.id &&
                              openResellerDetailDropdown.type === "status"
                            }
                            toggleDropdown={() =>
                              handleDropdownToggleResellerDetail(
                                reseller.id,
                                "status"
                              )
                            }
                          /> */}
                        </div>
                        <div className="d-flex flex-column mt-3 reseller-de-card">
                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <UserSquare
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Person Name :{" "}
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerDetails.name}
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <EnvelopeSimple
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                E-mail :{" "}
                              </p>
                            </div>
                            <p className="mb-0">{resellerDetails.email}</p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <Phone size={20} color="#212b36" weight="regular" />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Mobile No. :
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerDetails.company?.mobile_number}
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <User size={20} color="#212b36" weight="regular" />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Login ID:
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerDetails.username}
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <Global
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                State:
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerDetails.state?.name}
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <City size={20} color="#212b36" weight="regular" />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                City:
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerDetails.city?.name}
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <CalendarPlus
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Created on :
                              </p>
                            </div>
                            <p className="mb-0 text-capitalize">
                              {moment(resellerDetails.created_at).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <CalendarCheck
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Updated on :
                              </p>
                            </div>
                            <p className="mb-0 text-capitalize">
                              {moment(resellerDetails.updated_at).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12} lg={6} md={12} xs={12}>
                    <div className="bg-white rounded-3 p-4 h-100">
                      <div>
                        <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                          <span className="rounded-circle"></span>
                          <p className="mb-0">Company Details</p>
                        </div>

                        <div className="d-flex flex-column mt-3 reseller-de-card">
                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <BuildingOffice
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Company Name :
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerDetails.company?.name}
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <LinkIcon
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Domain URL :
                              </p>
                            </div>
                            <p className="mb-0">
                            {resellerDetails.company?.domain_url ? `https://${resellerDetails.company.domain_url}` : '-'}                            
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <Browser
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Website :
                              </p>
                            </div>
                            <p className="mb-0">
                            {resellerDetails.company?.website ? `https://${resellerDetails.company.website}` : '-'}                            
                            </p>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <MapPin
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Address :
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerDetails.company?.address ? resellerDetails.company?.address : '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} xs={12}>
                    <div className="bg-white rounded-3 p-4 h-100">
                      <div>
                        <div className="d-flex align-items-center justify-content-between gap-3">
                          <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                            <span className="rounded-circle"></span>
                            <p className="mb-0">Credits Details</p>
                          </div>
                        </div>
                        <div className="float-end my-n5">
                          <Button
                            variant="primary"
                            onClick={handleShow}
                            style={{ height: "40px" }}
                          >
                            Add Credit
                          </Button>
                        </div>
                        <div className="d-flex flex-column mt-3 reseller-de-card">
                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <StackPlus
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Total Credits :
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerCreditDetail?.total_credits}
                            </p>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <Stack size={20} color="#212b36" weight="regular" />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Available Credits :
                              </p>
                            </div>
                            <p className="mb-0">
                              {resellerCreditDetail?.available_credits}
                            </p>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <Rupee size={20} color="#212b36" weight="regular" />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Total Recharge Amount :
                              </p>
                            </div>
                            <p className="mb-0">
                              ₹ {resellerCreditDetail?.total_recharge_amount}
                            </p>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <CreditIcon
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Last Credit alloted :
                              </p>
                            </div>
                            <p className="mb-0">
                             {
                              resellerCreditDetail?.last_recharge_history
                                ?.credits ? (
                                  <td>{resellerCreditDetail?.last_recharge_history?.credits}</td>
                                ):(
                                  <td>-</td>
                                )
                            }
                            </p>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center re-title ">
                              <CalendarIcon
                                size={20}
                                color="#212b36"
                                weight="regular"
                              />
                              <p className="mb-0 text-capitalize white-space-nowrap">
                                Last Recharge Date :
                              </p>
                            </div>
                            <p className="mb-0 text-capitalize">
                              {
                              resellerCreditDetail?.last_recharge_history
                              ?.created_at ? (
                                moment(
                                  resellerCreditDetail?.last_recharge_history
                                    ?.created_at
                                ).format("DD/MM/YYYY hh:mm A")
                              ) : "-"
                              }  
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xl={8} lg={12} md={12} xs={12}>
                <div>
                  <div className="position-relative overflow-hidden rounded-2 bg-white tablecontainer table-tabs">
                    <Tabs
                      defaultActiveKey="customerList"
                      className="pb-0  table-tabs-items"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey="customerList"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <UserList
                                size={22}
                                color="#637381"
                                weight="regular"
                              />
                              <p className="mb-0">Organization List</p>
                            </div>
                          </>
                        }
                      >
                        <div className="d-flex align-items-center justify-content-between my-4 table-tit-head">
                          <p className="m-0">Organization List</p>

                          <div className="position-relative">
                            <input
                              type="search"
                              placeholder="Search"
                              className="rounded-1"
                              value={searchTerm}
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(0);
                              }}
                            />
                            <MagnifyingGlass
                              size={18}
                              color="#757779"
                              className="position-absolute top-50 start-0 ms-4 translate-middle"
                              weight="regular"
                            />
                          </div>
                        </div>
                        <div className="table-responsive custom_table rounded-2">
                          <table className="w-100 rounded-2">
                            <thead>
                              <tr>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Organization Name</p>
                                    <span className="d-flex align-items-center">
                                      {renderSortCustomerIcons("name")}
                                    </span>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Person name</p>
                                    <span className="d-flex align-items-center"></span>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Mobile Number</p>
                                    <span className="d-flex align-items-center">
                                      {renderSortCustomerIcons("mobile_number")}
                                    </span>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Email</p>
                                    <span className="d-flex align-items-center">
                                      {renderSortCustomerIcons("email")}
                                    </span>
                                  </div>
                                </th>
                                {/* <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Company Name</p>
                                  </div>
                                </th> */}

                                {/* <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Country Code</p>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Country</p>
                                  </div>
                                </th> */}

                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Credits</p>
                                    <span className="d-flex align-items-center">
                                      {renderSortCustomerIcons("credits")}
                                    </span>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Status</p>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Created On</p>
                                  </div>
                                </th>
                                {/* <th className="fw-medium">
                                  <div className="d-flex align-items-center justify-content-center gap-th">
                                    <p className="mb-0">Action</p>
                                  </div>
                                </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {customers?.length > 0 ? (
                                customers.map((customer, i) => (
                                  <tr
                                    key={i}
                                    className="cursor-pointer position-relative"
                                    onClick={() => handleRowClick(customer)}
                                  >
                                    <td>{customer.organization_name}</td>
                                    <td>{customer.name}</td>
                                    {customer.mobile_number ? (
                                      <td>{customer.mobile_number}</td>
                                    ) : (
                                      <td className="text-center">-</td>
                                    )}

                                    {customer.email ? (
                                      <td>{customer.email}</td>
                                    ) : (
                                      <td className="text-center">-</td>
                                    )}

                                    {/* <td className="text-capitalize">{customer.company.name}</td> */}

                                    {/* {customer.country_code ? (
                                      <td className="text-center">
                                        {customer.country_code}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    )}

                                    {customer?.country?.name ? (
                                      <td className="text-capitalize text-center">
                                        {customer?.country?.name}
                                      </td>
                                    ) : (
                                      <td className="text-center">-</td>
                                    )} */}

                                    <td className="text-center">
                                      {customer.credits}
                                    </td>

                                    <td>
                                      {customer.is_active ? (
                                        <div className="active-btn text-capitalize rounded-pill text-center ">
                                          <p className="mb-0">Active</p>
                                        </div>
                                      ) : (
                                        <div className="in-active-btn text-capitalize rounded-pill text-center">
                                          <p className="mb-0">In Active</p>
                                        </div>
                                      )}
                                    </td>

                                    <td className="text-center">
                                      {moment(customer.created_at).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </td>

                                    {/* <td className="text-center sticky-td">
                                      <ActionMenu
                                        customer={customer}
                                        isOpen={
                                          openDropdown.id === customer.id &&
                                          openDropdown.type === "action"
                                        }
                                        toggleMenu={() =>
                                          handleDropdownToggle(
                                            customer.id,
                                            "action"
                                          )
                                        }
                                      />
                                    </td> */}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="7"
                                    className="text-center border-bottom-0"
                                  >
                                    <p className="mt-5 mb-0 no-data text-capitalize">
                                      No data available
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {total > limit && (
                          <div className="pt-4">
                            <PaginationComponent
                              totalItems={total}
                              itemsPerPage={limit}
                              currentPage={currentPage + 1}
                              onPageChange={handlePageChange}
                            />
                          </div>
                        )}
                      </Tab>
                      <Tab
                        eventKey="rechargeCreditHistory"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <ClockCounterClockwise
                                size={22}
                                color="#637381"
                                weight="regular"
                              />
                              <p className="mb-0">Recharge History</p>
                            </div>
                          </>
                        }
                      >
                        <div className="d-flex align-items-center justify-content-between my-4 table-tit-head">
                          <p className="m-0">Recharge History</p>
                          {/* <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip">Add Credit</Tooltip>
                            }
                          >
                            <Button
                              variant="link"
                              onClick={handleShow}
                              className="d-flex align-items-center justify-content-center p-0 creadit-date-btn"
                            >
                              <Plus size={16} color="#637381" weight="regular" />
                            </Button>
                          </OverlayTrigger> */}
                          <div className="float-end my-n5">
                            <Button
                              variant="primary"
                              onClick={handleShow}
                              style={{ height: "40px" }}
                            >
                              Add Credit
                            </Button>
                          </div>
                        </div>
                        <div className="table-responsive custom_table  rounded-2">
                          <table className="w-100 rounded-2 overflow-hidden">
                            <thead className="table-light">
                              <tr>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Purchase Date</p>
                                    <span className="d-flex align-items-center">
                                      {renderSortCreditIcons("created_at")}
                                    </span>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Credit Rate</p>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Credits</p>
                                    <span className="d-flex align-items-center">
                                      {renderSortCreditIcons("credits")}
                                    </span>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Total Price</p>
                                    <span className="d-flex align-items-center">
                                      {renderSortCreditIcons("price")}
                                    </span>
                                  </div>
                                </th>
                                <th className="fw-medium">
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">Status</p>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="credit_data">
                              {credits?.length > 0 ? (
                                credits.map((credit, i) => (
                                  <tr key={i}>
                                    <td>
                                      {moment(credit.created_at).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </td>
                                    {credit.credit_rate ? (
                                      <td>{`₹${credit.credit_rate}`}</td>
                                    ) : (
                                      <td>0.00</td>
                                    )}
                                    {credit.credits ? (
                                      <td>{credit.credits}</td>
                                    ) : (
                                      <td>0</td>
                                    )}
                                    {credit.price ? (
                                      <td>{`₹${credit.price}`}</td>
                                    ) : (
                                      <td>0.00</td>
                                    )}
                                    <td>
                                      {credit.status === "completed" ? (
                                        <div className="active-btn text-capitalize rounded-pill text-center ">
                                          <p className="mb-0">Completed</p>
                                        </div>
                                      ) : credit.status === "rejected" ? (
                                        <div className="in-active-btn text-capitalize rounded-pill text-center">
                                          <p className="mb-0">Rejected</p>
                                        </div>
                                      ) : (
                                        <div className="placed-btn text-capitalize rounded-pill text-center">
                                          <p className="mb-0">Requested</p>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="5"
                                    className="text-center border-bottom-0"
                                  >
                                    <p className="mt-5 mb-0 no-data text-capitalize">
                                      No data available
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {totalCredit > limit && (
                            <div className="pt-4">
                              <PaginationComponent
                                totalItems={totalCredit}
                                itemsPerPage={limit}
                                currentPage={currentCreditPage + 1}
                                onPageChange={handleCreditPageChange}
                              />
                            </div>
                          )}
                        </div>
                      </Tab>
                      <Tab
                        eventKey="creditHistory"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <Cardholder
                                size={22}
                                color="#637381"
                                weight="regular"
                              />
                              <p className="mb-0">Credit History</p>
                            </div>
                          </>
                        }
                      >
                        <div className="d-flex align-items-center justify-content-between my-4 table-tit-head">
                          <p className="m-0">Credit History</p>

                          <DateRangePicker
                            initialSettings={{
                              startDate: dateRange.startDate.toDate(),
                              endDate: dateRange.endDate.toDate(),
                              ranges: {
                                Today: [moment(), moment()],
                                Yesterday: [
                                  moment().subtract(1, "days"),
                                  moment().subtract(1, "days"),
                                ],
                                "Last 7 Days": [
                                  moment().subtract(6, "days"),
                                  moment(),
                                ],
                                "Last 30 Days": [
                                  moment().subtract(29, "days"),
                                  moment(),
                                ],
                                "This Month": [
                                  moment().startOf("month"),
                                  moment().endOf("month"),
                                ],
                                "Last Month": [
                                  moment().subtract(1, "month").startOf("month"),
                                  moment().subtract(1, "month").endOf("month"),
                                ],
                                "Last 3 Months": [
                                  moment().subtract(3, "month").startOf("month"),
                                  moment().subtract(1, "month").endOf("month"),
                                ],
                                "Last 6 Months": [
                                  moment().subtract(6, "month").startOf("month"),
                                  moment().subtract(1, "month").endOf("month"),
                                ],
                                "This Year": [
                                  moment().startOf("year"),
                                  moment().endOf("year"),
                                ],
                              },
                              locale: {
                                format: "YYYY-MM-DD",
                              },
                            }}
                            onApply={(e, _) => handleEvent(e, _)}
                          >
                            <button className="btn btn-primary">
                              {dateRange.startDate.format("DD/MM/YYYY")} -{" "}
                              {dateRange.endDate.format("DD/MM/YYYY")}
                            </button>
                          </DateRangePicker>
                        </div>
                        <div className="">
                          <Row className="my-5 g-4 cerdit-history-content">
                            <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                              <div className="p-4 rounded-3 cedit-h-card h-100" style={{width:"236px"}}>
                                <div className="d-flex align-items-start justify-content-between gap-3">
                                  <div>
                                    <p className="mb-0 text-capitalize">
                                      Opening Balance
                                    </p>
                                    <span>
                                      {creditLogBooks.opening_balance || 0}
                                    </span>
                                  </div>
                                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                    <Coins
                                      size={20}
                                      color="#624BFF"
                                      weight="duotone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>

                            <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                              <i className="fas fa-plus-circle fa-1x" style={{ color: "#624BFF", marginLeft:"78px" }}></i>
                            </Col>

                            <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                              <div className="p-4 rounded-3 cedit-h-card h-100" style={{width:"236px"}}>
                                <div className="d-flex align-items-start justify-content-between gap-3">
                                  <div>
                                    <p className="text-capitalize">
                                      Purchase Credit
                                    </p>
                                    <span>
                                      {creditLogBooks.total_credits || 0}
                                    </span>
                                    <div className="d-flex">
                                    <div  style={{fontSize:"small"}}>Total Purchase Amount:  </div>
                                    <div style={{fontSize:"small"}}>₹{creditLogBooks.total_purchase_amount|| 0}</div>
                                  </div>
                                  </div>
                                 
                                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                    <StackPlus
                                      size={20}
                                      color="#624BFF"
                                      weight="duotone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>

                            <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                            <i className="fas fa-minus-circle fa-1x" style={{ color: "#624BFF", marginLeft:"78px" }}></i>
                          </Col>

                            <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                              <div className="p-4 rounded-3 cedit-h-card h-100" style={{width:"236px"}}>
                                <div className="d-flex align-items-start justify-content-between gap-3">
                                  <div>
                                    <p className="text-capitalize">
                                      Selling Credit
                                    </p>
                                    <span>
                                      {creditLogBooks.total_debits || 0}
                                    </span>
                                  <div className="d-flex">
                                    <div  style={{fontSize:"small"}}>Total Selling Amount:  </div>
                                    <div style={{fontSize:"small"}}>₹{creditLogBooks.total_selling_amount || 0}</div>
                                  </div>
                                  </div>
                                  {/* <div>
                                    <p className="text-capitalize">
                                      Total Selling Amount
                                    </p>
                                    <div>
                                      {Math.ceil(creditLogBooks?.total_selling_amount) || 0}
                                    </div>
                                  </div> */}
                                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                    <StackMinus
                                      size={20}
                                      color="#624BFF"
                                      weight="duotone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>

                            <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                              <i className="fas fa-equals fa-1x" style={{ color: "#624BFF", marginLeft:"78px" }}></i>
                            </Col>
                            
                            <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                              <div className="p-4 rounded-3 cedit-h-card h-100" style={{width:"236px"}}>
                                <div className="d-flex align-items-start justify-content-between gap-3">
                                  <div>
                                    <p className="text-capitalize">
                                      Closing Balance
                                    </p>
                                    <span>
                                      {creditLogBooks.closing_balance || 0}
                                    </span>
                                  </div>
                                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                    <MoneyWavy
                                      size={20}
                                      color="#624BFF"
                                      weight="duotone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="table-responsive custom_table  rounded-2">
                            <table className="w-100 rounded-2 overflow-hidden">
                              <thead>
                                <tr>
                                  <th className="fw-medium">
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">Date</p>
                                    </div>
                                  </th>
                                  <th className="fw-medium">
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">Description</p>
                                    </div>
                                  </th>
                                  <th className="fw-medium">
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">Rate</p>
                                    </div>
                                  </th>
                                  <th className="fw-medium">
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">Amount</p>
                                    </div>
                                  </th>
                                  <th className="fw-medium">
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">Message Credit</p>
                                    </div>
                                  </th>
                                  <th className="fw-medium">
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">Message Debit</p>
                                    </div>
                                  </th>
                                  <th className="fw-medium">
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">Balance</p>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="cu-tbody">
                                {creditLogBooksData?.length > 0 ? (
                                  creditLogBooksData.map((credit, i) => (
                                    <tr key={i} className="align-middle data_row">
                                      <td>
                                        {moment(credit.created_at).format(
                                          "DD/MM/YYYY hh:mm A"
                                        )}
                                      </td>
                                      {credit?.description ? (
                                        <td>{credit.description}</td>
                                      ) : (
                                        <td>
                                          {credit.is_credited
                                            ? `Credits added by Admin`
                                            : `Credits transferred to ${credit.customer.name}`}
                                        </td>
                                      )}
                                      {credit.credit_rate ? (
                                        <td>{`₹${credit.credit_rate}`}</td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                      {credit.price ? (
                                        <td>{`₹${credit.price}`}</td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                      {credit.is_credited && credit.credits ? (
                                        <td>
                                          {credit.is_credited && credit.credits}
                                        </td>
                                      ) : (
                                        <td>0</td>
                                      )}
                                      {!credit.is_credited && credit.credits ? (
                                        <td>
                                          {!credit.is_credited && credit.credits}
                                        </td>
                                      ) : (
                                        <td>0</td>
                                      )}
                                      <td>
                                        {credit?.opening_balance
                                          ? `${credit?.is_credited? credit.opening_balance + credit.credits : credit.opening_balance - credit.credits}`
                                          : "-"}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="text-center border-bottom-0"
                                    >
                                      <p className="my-5  no-data text-capitalize">
                                        No data available
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                             
                              <tfoot>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">Total</p>
                                    </div>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">
                                        {creditLogBooks.total_credits}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">
                                        {creditLogBooks.total_debits}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center gap-th">
                                      <p className="mb-0">{}</p>
                                    </div>
                                  </td>
                                </tr>
                              
                              </tfoot>
                            {/*   */}
                            </table>
                          
                          </div>
                          {
                            creditLogBooks?.total > limit && (
                              <div className="pt-4">
                                <PaginationComponent
                                  totalItems={creditLogBooks?.total}
                                  itemsPerPage={limit}
                                  currentPage={currentCreditHistoryPage + 1}
                                  onPageChange={handleCreditHistoryPageChange}
                                />
                              </div>
                            )
                          }
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        {currentCustomer && (
          <CustomerDetailModal
            show={showCustomerDetailModal}
            handleClose={() => setShowCustomerDetailModal(false)}
            customer={currentCustomer}
            handleResellerClose={handleCloseModal}
            resellerDetails={resellerDetails}
          />
        )}
        {resellerDetails && resellerDetails.company_id && (
          <Credit
            show={showModal}
            handleClose={handleClose}
            companyId={resellerDetails.company_id}
            resellerId={resellerDetails.id}
            itemsPerPage={limit}
          />
        )}
      </>
    );
  }
