import React, { useState } from "react";
import "../../../styles/theme.scss";
import Sidebar from "./navbar/Sidebar";
import NavbarTop from "./navbar/NavbarTop";

export default function DashboardLayout({ children }) {
  const [showMenu, setShowMenu] = useState(true);
  const ToggleMenu = () => {
    return setShowMenu(!showMenu);
  };

  return (
    <div id="db-wrapper" className={`${showMenu ? "" : "toggled"}`}>
      <div className="navbar-vertical navbar">
        <Sidebar showMenu={showMenu} onClick={(value) => setShowMenu(value)} />
      </div>
      <div id="page-content">
        <div className="header">
          <NavbarTop
            data={{
              showMenu: showMenu,
              SidebarToggleMenu: ToggleMenu,
            }}
          />
        </div>
        <div className="mt-9 pt">{children}</div>
      </div>
    </div>
  );
}
