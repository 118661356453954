import React, { Fragment } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Fragment>
      <Container>
        <Row>
          <Col sm={12}>
            <div className="text-center mt-22">
              <div className="mb-3">
                <img src="/images/notFound/404-error-img.png" alt="" className="img-fluid" />
              </div>
              <h1 className="display-4 fw-bold">Oops! the page not found.</h1>
              <Link to="/dashboard" className="btn btn-primary">
                Go Home
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NotFound;
