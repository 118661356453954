import React from "react";
import CommonLayout from "../../layouts/master/index";
import List from "./List";

export default function Inquiry() {

  return (
    <>
      <CommonLayout>
        <div>
          <List/>          
        </div>
      </CommonLayout>
    </>
  );
}
