import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Col,
  Row,
  Button,
  Toast,
  Spinner,
  ToastContainer
} from "react-bootstrap";
// import "./profile.css";
import CommonLayout from "../../layouts/master/index";
import { updateSettings, fetchSettings } from "../../../store/resellerSlice";
import { Formik, Field, Form } from "formik";
import {settingSchema} from "../../../schema/validationSchemas" 

import {
  CheckCircle,
} from "../../../constant/icons/icons";

export default function Detail() {
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [resellerDefaultCredit, setResellerDefaultCredit] = useState("");
  const [customerDefaultCredit, setCustomerDefaultCredit] = useState("");

useEffect(() => {
  dispatch(fetchSettings()).then((action) => {
    if (action.payload && action.payload.message && action.payload.message.settings) {
      const settings = action.payload.message.settings;
      
      const resellerCredit = settings.reseller_default_credits;
      const customerCredit = settings.customer_default_credits;
      
      setResellerDefaultCredit(resellerCredit !== undefined ? resellerCredit : "");
      setCustomerDefaultCredit(customerCredit !== undefined ? customerCredit : "");
    }
  });
}, [dispatch]);


  const handleProfileSubmit = (values, { setSubmitting, setErrors }) => {
    const settingsData = [
      { key: "reseller_default_credits", value: values.resellerDefaultCredit || 0 },
      { key: "customer_default_credits", value: values.customerDefaultCredit || 0 }
    ];
  
    dispatch(updateSettings(settingsData))
      .then((action) => {
        if (action.payload && action.payload.success) {
          setSnackbarMessage("Settings updated successfully!");
          setSnackbarVariant("success");
        } else {
          setErrors(action.payload.message);
          setSnackbarMessage(action.payload.message || "Failed to update settings");
          setSnackbarVariant("danger");
        }
      })
      .catch((error) => {
        setSnackbarMessage("Update failed: Network or server error" + error.message);
        setSnackbarVariant("danger");
      })
      .finally(() => {
        setShowSnackbar(true);
        setSubmitting(false);
      });
  };

  return (
    <CommonLayout>
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div>
                <h3 className="mb-0 fw-semibold pg-title text-white">
                  Default Credits
                </h3>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="rounded-2 bg-white tablecontainer table-tabs">
                  <Formik
                    enableReinitialize
                    initialValues={{
                        resellerDefaultCredit: resellerDefaultCredit,
                        customerDefaultCredit: customerDefaultCredit
                      }}
                    validationSchema={settingSchema}
                    onSubmit={handleProfileSubmit}
                  >
                    {({ isSubmitting, errors, touched, handleChange }) => (
                      <Form>
                        <div className="d-inline-block w-100 profile-content">
                          <Row className="flex-column">
                            <Col xl={6} lg={6} md={6} xs={6}>
                              <div class="d-flex align-items-center justify-content-between mb-4 pg-sub-title">
                                <p class="m-0">Add Credits</p>
                              </div>
                            </Col>

                            <Col
                              xl={6}
                              lg={6}
                              md={6}
                              xs={6}
                              className="border rounded-3 p-4"
                            >
                              <Row className="gy-3">
                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <label className="form-label ms-1">
                                  Reseller Credit
                                  </label>
                                  <Field
                                    name="resellerDefaultCredit"
                                    type="number"
                                    min="0"
                                    className={`form-control shadow-none ${
                                      errors.resellerDefaultCredit && touched.resellerDefaultCredit
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter Reseller Credit"
                                    onChange={handleChange}
                                  />
                                  {errors.resellerDefaultCredit && touched.resellerDefaultCredit ? (
                                    <div className="invalid-feedback">
                                      {errors.resellerDefaultCredit}
                                    </div>
                                  ) : null}
                                </Col>

                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <label className="form-label ms-1">
                                    Organization Credit
                                  </label>
                                  <Field
                                    name="customerDefaultCredit"
                                    type="number"
                                    min="0"
                                    className={`form-control shadow-none ${
                                      errors.customerDefaultCredit && touched.customerDefaultCredit
                                        ? "is-invalid"
                                        : ""
                                    } `}
                                    placeholder="Enter Organization Credit"
                                    onChange={handleChange}
                                  />
                                  {errors.customerDefaultCredit && touched.customerDefaultCredit ? (
                                    <div className="invalid-feedback">
                                      {errors.customerDefaultCredit}
                                    </div>
                                  ) : null}
                                </Col>

                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    variant="link"
                                    className="fr-submit-btn mt-2"
                                  >
                                    {isSubmitting ? (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      "Add"
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    )}
                  </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </CommonLayout>
  );
}
