import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Email is required'),
    password: Yup.string().min(8, 'Minimum password length is 8').max(30, 'Maximum password length is 30').required('Password is required'),
});

// export const resellerSchema = Yup.object().shape({
//   name: Yup.string()
//   .required('Name is required')
//   .min(2, 'Name should be at least 2 characters')
//   .max(50, 'Name should not exceed 50 characters')
//   .matches(/^[a-zA-Z\s\-']+$/, 'Name should not contain numbers or special characters except space, dash, and apostrophe'),
//   username: Yup.string()
//   .required('Username is required'),
//   state_id: Yup.string()
//   .required('State is required'),
//   city_id: Yup.string()
//   .required('City is required'),
//   password: Yup.string()
//     .min(8, 'Minimum password length is 8')
//     .max(30, 'Maximum password length is 30')
//     .required('Password is required'),
//   password_confirmation: Yup.string()
//     .oneOf([Yup.ref('password'), null], 'Passwords must match')
//     .required('Confirm password is required'),

//   company_name: Yup.string().required('Company name is required'),
//   company_mobile_number: Yup.string()
//   .required('Company mobile number is required'),
//   email: Yup.string()
//   .email('Invalid email address')
//   .required('Company email is required')
//   .matches(
//     /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
//     'Invalid email format'
//   ),
//   company_short_id: Yup.string()
//   .required('Company short id is required'),
//   // company_domain_url: Yup.string()
//   // .required('Company domain URL is required')
//   // .test(
//   //   'is-valid-domain',
//   //   'Invalid domain URL format: example.com',
//   //   value => /^(https:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})$/.test(value)
//   // ),
// });

export const editResellerSchema = Yup.object().shape({
  name: Yup.string()
  .required('Name is required')
  .min(2, 'Name should be at least 2 characters')
  .max(50, 'Name should not exceed 50 characters')
  .matches(/^[a-zA-Z\s\-']+$/, 'Name should not contain numbers or special characters except space, dash, and apostrophe'),
  username: Yup.string()
  .required('Username is required'),
  state_id: Yup.string()
  .required('State is required'),
  city_id: Yup.string()
  .required('City is required'),

  company_name: Yup.string().required('Company name is required'),
  company_mobile_number: Yup.string()
  .required('Company mobile number is required'),
  email: Yup.string()
  .email('Invalid email address')
  .required('Company email is required')
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
    'Invalid email format'
  ),
  company_short_id: Yup.string()
  .required('Company short id is required'),
  // company_domain_url: Yup.string()
  // .required('Company domain url is required'),
  // company_address: Yup.string()
  // .required('Company address is required'),
  // company_website: Yup.string()
  //   .required('Company website is required')
  //   .url('Invalid website format: http://www.example.com'), 
  // company_logo: Yup.string()  
  //   .required('Company logo is required'),
});

export const profileSchema = Yup.object().shape({
  name: Yup.string()
  .required('Name is required')
  .min(2, 'Name should be at least 2 characters')
  .max(50, 'Name should not exceed 50 characters')
  .matches(/^[a-zA-Z\s\-']+$/, 'Name should not contain numbers or special characters except space, dash, and apostrophe'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      'Invalid email format'
    )
});

export const passwordSchema = Yup.object().shape({
  current_password: Yup.string().required('Current password is required')
  .min(8, 'Minimum current password length is 8')
  .max(30, 'Maximum current password length is 30'),
  new_password: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('New password is required'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const creditSchema = Yup.object().shape({
  credits: Yup.number()
    .typeError('Credits must be a number')
    .min(1, 'Credits must be at least 1')
    .required('Credits are required'),
  price_per_credits: Yup.number()
    .typeError('Price per credit rate must be a number')
    .min(0.1, 'Price per credit must be greater than 0.1')
    .max(10000000, 'Price per credit must be less than 10000000')
    .required('Price per credit rate is required'),
});

export const settingSchema = Yup.object().shape({
  resellerDefaultCredit: Yup.number()
    .typeError('Reseller default credit must be a number')
    .min(0, 'Reseller default credit must be greater than or equal to 0')
    .required('Reseller default credit is required'),
  customerDefaultCredit: Yup.number()
    .typeError('Customer default credit must be a number')
    .min(0, 'Customer default credit must be greater than or equal to 0')
    .required('Customer default credit is required'),
});

export const organizationTypeSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
  .min(3, 'Minimum Name length is 3')
  .max(50, 'Maximum Name length is 50'),
});

export const requestCreditSchema = Yup.object().shape({
  credits: Yup.number()
    .typeError('Credits must be a number')
    .min(1, 'Credits must be at least 1')
    .required('Credits are required'),
  price_per_credits: Yup.number()
    .typeError('Price per credit rate must be a number')
    .min(0.1, 'Price per credit must be greater than 0.1')
    .max(10000000, 'Price per credit must be less than 10000000')
    .required('Price per credit rate is required'),
});

export const requestedAddCreditSchema = Yup.object().shape({
  resellerId: Yup.string()
  .required('Reseller is required'),
  credits: Yup.number()
    .typeError('Credits must be a number')
    .min(1, 'Credits must be at least 1')
    .required('Credits are required'),
  price_per_credits: Yup.number()
    .typeError('Price per credit rate must be a number')
    .min(0.1, 'Price per credit must be greater than 0.1')
    .max(10000000, 'Price per credit must be less than 10000000')
    .required('Price per credit rate is required'),
});

