import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  // ToastContainer,
  // Toast,
  // Badge,
} from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import PaginationComponent from "../pagination/pagination";
import { useLoader } from "../../../context/LoaderContext";
import {
  MagnifyingGlass,
  // CheckCircle,
} from "../../../constant/icons/icons";
import { fetchInquiries } from "../../../store/resellerSlice";
// import Edit from "./Edit";
import moment from "moment";

export default function List() {
  const { setLoading } = useLoader();
  const limit = 10;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  // const [showSnackbar, setShowSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarVariant, setSnackbarVariant] = useState("success");

  // const { total, requestedCredits } = useSelector((state) => ({
  //   total: state.reseller?.requestedCredits?.total,
  //   requestedCredits: state.reseller?.requestedCredits?.data,
  // }));
  const inquiries = useSelector((state) => state.reseller.inquiries);

  // const toastMessage = localStorage.getItem("toastMessageOrganizationType");

  // useEffect(() => {
  //   if (toastMessage) {
  //     setShowSnackbar(true);
  //     setSnackbarMessage(toastMessage);
  //     setSnackbarVariant("success");
  //     localStorage.removeItem("toastMessageOrganizationType");

  //     if (toastMessage === "Validation failed") {
  //       setShowSnackbar(true);
  //       setSnackbarMessage(toastMessage);
  //       setSnackbarVariant("danger");
  //       localStorage.removeItem("toastMessageOrganizationType");
  //     }
  //   }
  // }, [toastMessage]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
      role="button"
    >
      {children}
    </div>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const getRequestedCreditList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(
        fetchInquiries({
          offset: currentPage,
          limit,
          search: searchTerm,
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading, currentPage, limit, searchTerm]);

  useEffect(() => {
    getRequestedCreditList();
  }, [getRequestedCreditList]);

  return (
    <>
      {/* <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer> */}

      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6 pb-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div>
                <h3 className="mb-0 fw-semibold pg-title text-white">
                  Requested Credits
                </h3>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative rounded-3 bg-white  tablecontainer">
              <div className="d-flex align-items-center justify-content-between gap-3 reseller-sea-btn">
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Search "
                    className="searchbar outline-none border-0 shadow-none "
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setCurrentPage(0);
                    }}
                  />
                  <MagnifyingGlass
                    size={18}
                    color="#757779"
                    className="position-absolute top-50 start-0 ms-4 translate-middle"
                    weight="regular"
                  />
                </div>
              </div>

              <div className="rounded-2 custom_table mt-5">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Name</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("name")}
                          >
                            {/* {renderSortIcons("name")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Email</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("name")}
                          >
                            {/* {renderSortIcons("name")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Subject</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("credit_rate")}
                          >
                            {/* {renderSortIcons("credit_rate")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Message</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("credit_rate")}
                          >
                            {/* {renderSortIcons("credit_rate")} */}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Created At</p>
                          <span
                            className="d-flex align-items-center"
                            // onClick={() => handleSort("name")}
                          >
                            {/* {renderSortIcons("name")} */}
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inquiries?.data?.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center border-bottom-0">
                          <p className="mt-5 mb-0 no-data text-capitalize">
                            No data available
                          </p>
                        </td>
                      </tr>
                    ) : (
                      inquiries?.data?.map((inquiry) => (
                        <tr key={inquiry?.id} className="position-relative">
                          <td>{inquiry?.name || '-'}</td>
                          <td>{inquiry?.email || '-'}</td>
                          <td>{inquiry?.subject || '-'}</td>
                          <td>{inquiry?.message || '-'}</td>
                          <td>
                            {moment(inquiry?.created_at).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              {inquiries?.total > limit && (
                <div className="pt-4">
                  <PaginationComponent
                    totalItems={inquiries?.total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
