import React, { Fragment, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Image, Dropdown, ListGroup, Spinner } from "react-bootstrap";
import "simplebar/dist/simplebar.min.css";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../../../../styles/images/avatar/avatar-1.jpg";
import useMounted from "../../../../hooks/useMounted";
import { userLogout } from "../../../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Toast, ToastContainer } from "react-bootstrap";
import { User, SignOut, CheckCircle } from "../../../../constant/icons/icons";

const NavbarTopMenu = () => {
  const hasMounted = useMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [showLoader, setShowLoader] = useState(false);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const user = useSelector((state) => state.auth.user || { data: {} });

  const handleLogOut = async () => {
    setShowLoader(true);
    try {
      await dispatch(userLogout()).then((res) => {
        setToastMessage(res.payload.message || "Logout successful");
        setToastVariant("success");
        setShowToast(true);
        setTimeout(() => {
          if (hasMounted) {
            navigate("/", { replace: true });
          }
        }, 2000);
      });
    } catch (error) {
      setToastMessage("Logout failed");
      setToastVariant("danger");
      setShowToast(true);
      setTimeout(() => {
        if (hasMounted) {
          navigate("/", { replace: true });
        }
      }, 2000);
    } finally {
      setTimeout(() => {
        setShowLoader(false);
        if (hasMounted) {
          navigate("/", { replace: true });
        }
      }, 2000);
    }
  };

  const LoaderPage = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        zIndex: "1",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <Spinner animation="border" role="status" variant="light">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );

  const getUserInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "U";
  };

  const NavbarTopMenuDesktop = () => {
    return (
      <>
      <div className="mx-4 my-2"><strong>{user.name}</strong></div>
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-auto d-flex nav-top-wrap"
      >
        
        <Dropdown as="li">
          
          <Dropdown.Toggle
            as="div"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
           
            <span
              className="rounded-circle bg-primary d-flex justify-content-center align-items-center"
              style={{
                width: 40,
                height: 40,
                color: "white",
                fontSize: "1.25rem",
              }}
            >
              {getUserInitial(user.name)}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            as="div"
            className="dropdown-menu dropdown-menu-end "
            align="end"
            aria-labelledby="dropdownUser"
            show
          >
            <Dropdown.Item as="div" className="px-4 pb-0 pt-2" bsPrefix=" ">
              <div className="lh-1">
                <h5 className="text-capitalize mb-1">
                  {user.name || user?.data?.name}
                </h5>
                <Link className="text-inherit fs-6" href="#">
                  View my profile
                </Link>
              </div>
              <div className="dropdown-divider mt-3 mb-2"></div>
            </Dropdown.Item>

            <Dropdown.Item as="div">
              <Link to="/my-account">
                <User
                  size={16}
                  color="#161C24"
                  className="me-2"
                  weight="regular"
                />
                My Account
              </Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogOut} as="div">
              <Link to="">
                <SignOut
                  size={16}
                  color="#161C24"
                  className="me-2"
                  weight="regular"
                />
                Log Out
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
      </>
    );
  };

  const NavbarTopMenuMobile = () => {
    return (
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-auto d-flex nav-top-wrap"
      >
        <Dropdown as="li" className="ms-2">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
            <div className="avatar avatar-md avatar-indicators avatar-online">
              <Image alt="avatar" src={avatar} className="rounded-circle" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu dropdown-menu-end "
            align="end"
            aria-labelledby="dropdownUser"
          >
            <Dropdown.Item as="div" className="px-4 pb-0 pt-2" bsPrefix=" ">
              <div className="lh-1">
                <h5 className="mb-1">John E. Grainger</h5>
                <Link to="#" className="text-inherit fs-6">
                  View my profile
                </Link>
              </div>
              <div className="dropdown-divider mt-3 mb-2"></div>
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <i className="fe fe-user me-2"></i> Edit Profile
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <i className="fe fe-activity me-2"></i> Activity Log
            </Dropdown.Item>
            <Dropdown.Item className="text-primary" as="div">
              <i className="fe fe-star me-2"></i> Go Pro
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <i className="fe fe-settings me-2"></i> Account Settings
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogOut} as="div">
              <i className="fe fe-power me-2"></i>Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
    );
  };

  return (
    <Fragment>
      {hasMounted && isDesktop ? (
        <NavbarTopMenuDesktop />
      ) : (
        <NavbarTopMenuMobile />
      )}
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{toastMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {showLoader && <LoaderPage />}
    </Fragment>
  );
};

export default NavbarTopMenu;
