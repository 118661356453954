import {
  Row,
  Col,
  Card,
  Button,
  Toast,
  ToastContainer,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../../store/authSlice";
import { loginSchema } from "../../../schema/validationSchemas";
import { useNavigate } from "react-router-dom";
import { CheckCircle, Eye, EyeSlash } from "../../../constant/icons/icons";

const LogIn = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [showPassword, setShowPassword] = useState(true);
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await dispatch(userLogin(values)).unwrap();
      if (result && result.success) {
        setToastMessage(result.message || "Login successful!");
        setToastVariant("success");
        setShowToast(true);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } else {
        setToastMessage(result.message || "Failed to login!");
        setToastVariant("danger");
        setShowToast(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (err) {
      const errorMessage = err?.message || "Failed to login!";
      setToastMessage(errorMessage);
      setToastVariant("danger");
      setShowToast(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant("danger");
      setShowToast(true);
    }

    if (status === "loading") {
      setToastMessage("Logging in...");
      setToastVariant("warning");
      setShowToast(true);
    }
  }, [status, error]);

  return (
    <div className="d-flex flex-column bg-light login-bg">
      <div className="container">
        <Row className="align-items-center justify-content-center g-0 min-vh-100 ">
          <Col xxl={5} lg={6} md={8} xs={12} className="py-8 py-xl-0">
            <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
              <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={5000}
                autohide
                bg={toastVariant}
              >
                <Toast.Body className="d-flex align-items-center gap-2 text-white">
                  <CheckCircle size={22} color="#ffffff" weight="duotone" />
                  <p className="mb-0">{toastMessage}</p>
                </Toast.Body>
              </Toast>
            </ToastContainer>
            <Card className="smooth-shadow-md">
              <Card.Body className="px-5 py-6  loginpage-content">
                <div className="text-center">
                  <Link to="/">
                    <img
                      src="/images/Domylogoes/Logo.svg"
                      className="img-fluid d-inline-block"
                      alt=""
                    />
                  </Link>

                  <h1 className="d-flex mb-0 fw-bold justify-content-center mt-3">
                    Admin Panel
                  </h1>
                  {/* <p className="text-center pt-2 mt-1 mb-0 mx-4">
                    "With a vision for a smarter tomorrow, committed to
                    delivering exceptional technology for your success."
                  </p> */}
                </div>
                <Formik
                  initialValues={{
                    email: "admin@gmail.com",
                    password: "123456789",
                  }}
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} className="mt-4">
                      <div className="">
                        <label htmlFor="email" className="form-label mb-1">
                          Email
                        </label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Enter your email here"
                          className={`form-control shadow-none ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email ? (
                          <div className="invalid-feedback">{errors.email}</div>
                        ) : null}
                      </div>

                      <div className="mt-3">
                        <label htmlFor="password" className="form-label mb-1">
                          Password
                        </label>
                        <InputGroup>
                          <Field
                            id="password"
                            name="password"
                            type={!showPassword ? "text" : "password"}
                            placeholder="Enter password"
                            className={`form-control shadow-none ${
                              touched.password && errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <InputGroup.Text
                            onClick={() => setShowPassword(!showPassword)}
                            className="eye-icon"
                          >
                            {showPassword ? (
                              <EyeSlash
                                size={19}
                                color="#637381"
                                weight="duotone"
                                className="cursor-pointer"
                              />
                            ) : (
                              <Eye
                                size={19}
                                color="#637381"
                                weight="duotone"
                                className="cursor-pointer"
                              />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        {errors.password && touched.password && (
                          <div className="invalid-feedback d-block">
                            {errors.password}
                          </div>
                        )}
                      </div>

                      <div className="d-grid w-28 d-flex justify-content-center mt-6">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Log In"
                          )}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LogIn;
