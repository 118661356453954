import React from "react";
import CommonLayout from "../../layouts/master/index";
import List from "./List";
import Edit from "./Edit";

export default function RequestedCredit() {

  return (
    <>
      <CommonLayout>
        <div>
          <List/>          
            <Edit/>        
        </div>
      </CommonLayout>
    </>
  );
}
