import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Col,
  Row,
  Button,
  Toast,
  Spinner,
  ToastContainer,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import "./profile.css";
import CommonLayout from "../../layouts/master/index";
import {
  userDetails,
  userProfile,
  userUpdatePassword,
} from "../../../store/authSlice";
import { Formik, Field, Form } from "formik";
import {
  profileSchema,
  passwordSchema,
} from "../../../schema/validationSchemas";

import {
  User,
  Gear,
  Eye,
  EyeSlash,
  CheckCircle,
} from "../../../constant/icons/icons";

export default function Detail() {
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialPasswordUpdate = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  useEffect(() => {
    dispatch(userDetails()).then((action) => {
      if (action.payload && action.payload.success) {
        setName(action.payload.data.name);
        setEmail(action.payload.data.email);
      }
    });
  }, [dispatch]);

  const handleProfileSubmit = (values, { setSubmitting, setErrors }) => {
    dispatch(userProfile(values))
      .then((action) => {
        if (action.payload && action.payload.data.success) {
          setSnackbarMessage(action.payload.data.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
        } else {
          setErrors(action.payload.message);
          setSnackbarMessage(
            action.payload.message || "Failed to update profile"
          );
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setSnackbarMessage(
          "Update failed: Network or server error" + error.message
        );
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handlePasswordChange = (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    dispatch(userUpdatePassword(values))
      .then((action) => {
        if (action.payload && action.payload.data.success) {
          setSnackbarMessage(action.payload.data.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          resetForm();
        } else {
          setErrors(action.payload.data.message || action.payload.data.current_password);
          setSnackbarMessage(
            action.payload.data.message || "Failed to update password"
          );
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(
          "Update failed: Network or server error" + error.message
        );
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <CommonLayout>
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div>
                <h3 className="mb-0 fw-semibold pg-title text-white">
                  My Account
                </h3>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="rounded-2 bg-white tablecontainer table-tabs">
              <Tabs
                defaultActiveKey="profilelist"
                className="mb-5 border-bottom-0 table-tabs-items"
              >
                <Tab
                  eventKey="profilelist"
                  title={
                    <>
                      <div className="d-flex align-items-center gap-2">
                        <User size={16} color="#637381" weight="bold" />
                        <p className="mb-0 text-capitalize">Profile</p>
                      </div>
                    </>
                  }
                >
                  <Formik
                    enableReinitialize
                    initialValues={{ name: name, email: email }}
                    validationSchema={profileSchema}
                    onSubmit={handleProfileSubmit}
                  >
                    {({ isSubmitting, errors, touched, handleChange }) => (
                      <Form>
                        <div className="d-inline-block w-100 profile-content">
                          <Row className="flex-column">
                            <Col xl={6} lg={6} md={6} xs={6}>
                              <div class="d-flex align-items-center justify-content-between mb-4 pg-sub-title">
                                <p class="m-0">Profile Information</p>
                              </div>
                            </Col>

                            <Col
                              xl={6}
                              lg={6}
                              md={6}
                              xs={6}
                              className="border rounded-3 p-4"
                            >
                              <Row className="gy-3">
                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <label className="form-label ms-1">
                                    Name
                                  </label>
                                  <Field
                                    name="name"
                                    type="text"
                                    className={`form-control shadow-none ${
                                      errors.name && touched.name
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter name"
                                    onChange={handleChange}
                                  />
                                  {errors.name && touched.name ? (
                                    <div className="invalid-feedback">
                                      {errors.name}
                                    </div>
                                  ) : null}
                                </Col>

                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <label className="form-label ms-1">
                                    Email
                                  </label>
                                  <Field
                                    name="email"
                                    type="text"
                                    className={`form-control shadow-none ${
                                      errors.email && touched.email
                                        ? "is-invalid"
                                        : ""
                                    } `}
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                  />
                                  {errors.email && touched.email ? (
                                    <div className="invalid-feedback">
                                      {errors.email}
                                    </div>
                                  ) : null}
                                </Col>

                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    variant="link"
                                    className="fr-submit-btn mt-2"
                                  >
                                    {isSubmitting ? (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      "Save"
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Tab>
                <Tab
                  eventKey="settingdetails"
                  title={
                    <>
                      <div className="d-flex align-items-center gap-2">
                        <Gear size={18} color="#637381" weight="bold" />
                        <p className="mb-0">Settings</p>
                      </div>
                    </>
                  }
                >
                  <Formik
                    initialValues={initialPasswordUpdate}
                    validationSchema={passwordSchema}
                    onSubmit={handlePasswordChange}
                  >
                    {({ isSubmitting, resetForm, errors, touched }) => (
                      <Form>
                        <div className="d-inline-block w-100 profile-content">
                          <Row className="flex-column">
                            <Col xl={6} lg={6} md={6} xs={6}>
                              <div class="d-flex align-items-center justify-content-between mb-4 pg-sub-title">
                                <p class="m-0">Settings</p>
                              </div>
                            </Col>

                            <Col
                              xl={6}
                              lg={6}
                              md={6}
                              xs={6}
                              className="border rounded-3 p-4"
                            >
                              <Row className="gy-3">
                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <label className="form-label ms-1">
                                    Current Password
                                  </label>
                                  <InputGroup>
                                    <Field
                                      name="current_password"
                                      type={
                                        showCurrentPassword
                                          ? "text"
                                          : "password"
                                      }
                                      as={FormControl}
                                      placeholder="Enter current password"
                                      isInvalid={
                                        errors?.current_password &&
                                        touched?.current_password
                                      }
                                      className="shadow-none"
                                    />
                                    <InputGroup.Text
                                      className="eye-spacing"
                                      onClick={() =>
                                        setShowCurrentPassword(
                                          !showCurrentPassword
                                        )
                                      }
                                    >
                                      {showCurrentPassword ? (
                                        <Eye
                                          size={19}
                                          color="#637381"
                                          weight="duotone"
                                          className="cursor-pointer"
                                        />
                                      ) : (
                                        <EyeSlash
                                          size={19}
                                          color="#637381"
                                          weight="duotone"
                                          className="cursor-pointer"
                                        />
                                      )}
                                    </InputGroup.Text>
                                  </InputGroup>
                                  {errors?.current_password &&
                                touched?.current_password && (
                                  <div className="invalid-feedback d-block">
                                    {errors?.current_password}
                                  </div>
                                )}
                                </Col>

                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <label className="form-label ms-1">
                                    New Password
                                  </label>
                                  <InputGroup>
                                    <Field
                                      name="new_password"
                                      type={
                                        showNewPassword ? "text" : "password"
                                      }
                                      as={FormControl}
                                      placeholder="Enter new password"
                                      isInvalid={
                                        errors?.new_password &&
                                        touched?.new_password
                                      }
                                      className="shadow-none"
                                    />
                                    <InputGroup.Text
                                      className="eye-spacing"
                                      onClick={() =>
                                        setShowNewPassword(!showNewPassword)
                                      }
                                    >
                                      {showNewPassword ? (
                                        <Eye
                                          size={19}
                                          color="#637381"
                                          weight="duotone"
                                          className="cursor-pointer"
                                        />
                                      ) : (
                                        <EyeSlash
                                          size={19}
                                          color="#637381"
                                          weight="duotone"
                                          className="cursor-pointer"
                                        />
                                      )}
                                    </InputGroup.Text>
                                  </InputGroup>
                                  {errors?.new_password &&
                                    touched?.new_password && (
                                      <div className="invalid-feedback ms-1 d-block">
                                        {errors?.new_password}
                                      </div>
                                    )}
                                </Col>

                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <label className="form-label ms-1">
                                    Confirmation Password
                                  </label>
                                  <InputGroup>
                                    <Field
                                      name="new_password_confirmation"
                                      type={
                                        showConfirmPassword
                                          ? "text"
                                          : "password"
                                      }
                                      as={FormControl}
                                      placeholder="Enter confirmation password"
                                      isInvalid={
                                        errors?.new_password_confirmation &&
                                        touched?.new_password_confirmation
                                      }
                                      className="shadow-none"
                                    />
                                    <InputGroup.Text
                                      className="eye-spacing"
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                    >
                                      {showConfirmPassword ? (
                                        <Eye
                                          size={19}
                                          color="#637381"
                                          weight="duotone"
                                          className="cursor-pointer"
                                        />
                                      ) : (
                                        <EyeSlash
                                          size={19}
                                          color="#637381"
                                          weight="duotone"
                                          className="cursor-pointer"
                                        />
                                      )}
                                    </InputGroup.Text>
                                  </InputGroup>
                                  {errors?.new_password_confirmation &&
                                    touched?.new_password_confirmation && (
                                      <div className="invalid-feedback ms-1 d-block">
                                        {errors.new_password_confirmation}
                                      </div>
                                    )}
                                </Col>

                                <Col xl={12} lg={12} md={12} xs={12}>
                                  <div className="d-flex align-items-center gap-3 mt-2">
                                    <Button
                                      type="button"
                                      className="pr-cl-btn border-0"
                                      onClick={() => resetForm()}
                                      disabled={isSubmitting}
                                    >
                                      Clear
                                    </Button>
                                    <Button
                                      type="submit"
                                      className="pr-sv-btn border-0"
                                      disabled={isSubmitting}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </CommonLayout>
  );
}
