import { UserCircleCheck, Buildings } from "../icons/icons";

// <------- 1.Add Reseller Tab Data -------> //

export const resellertabcontent = [
  {
    icon: <Buildings size={20} color="#637381" weight="regular" />,
    title: "Company Detail",
    content: "hello Company Detail",
  },
  
  {
    icon: <UserCircleCheck size={20} color="#637381" weight="regular" />,
    title: "Reseller Detail",
    content: "hello Reseller",
  },
  {
    icon: <UserCircleCheck size={20} color="#637381" weight="regular" />,
    title: "Other Detail",
    content: "hello Reseller",
  },
];
