  import React, { useState, useCallback, useRef } from "react";
  import { useDispatch } from "react-redux";
  import {
    Modal,
    Button,
    Toast,
    ToastContainer,
    Spinner,
    Row,
    Col,
  } from "react-bootstrap";
  import { Formik, Field, Form } from "formik";
  import { X, CheckCircle } from "../../../constant/icons/icons";
  import {
    addOrganizationType,
    fetchOrganizationTypes,
  } from "../../../store/organizationSlice";
  import { organizationTypeSchema } from "../../../schema/validationSchemas";

  export default function Add({ show, handleClose }) {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarVariant, setSnackbarVariant] = useState("success");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();
    const itemsPerPage = 10;

    const submitFormRef = useRef(null);

    const handleModalClose = useCallback(() => {
      handleClose();
    }, [handleClose]);

    return (
      <>
        <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
          <Toast
            onClose={() => setShowSnackbar(false)}
            show={showSnackbar}
            delay={3000}
            autohide
            bg={snackbarVariant}
          >
            <Toast.Body className="d-flex align-items-center gap-2 text-white">
              <CheckCircle size={22} color="#ffffff" weight="duotone" />
              <p className="mb-0">{snackbarMessage}</p>
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <Modal
          show={show}
          onHide={handleModalClose}
          dialogClassName="modal-dialog-scrollable add-reseller-popup reseller-popup-size"
          centered
        >
          <Modal.Header className="add-reseller-header d-inline-block p-0 border-bottom-0">
            <div className="d-flex align-items-center justify-content-between gap-3 br-bottom py-3 px-4">
              <h5 className="fw-semibold mb-0">Add Organization Type</h5>
              <div className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center x-arrow">
                <X size={18} color="" weight="bold" onClick={handleModalClose} />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="add-reseller-body">
            <Formik
              initialValues={{
                name: "",
              }}
              validationSchema={organizationTypeSchema}
              validate={(values) => {}}
              onSubmit={async (values, { resetForm, setErrors }) => {
                const formData = new FormData();
                formData.append("name", values.name);

                try {
                  const res = await dispatch(addOrganizationType(formData));
                  if (res.payload && res.payload.success) {
                    dispatch(
                      fetchOrganizationTypes({
                        offset: 0,
                        limit: itemsPerPage,
                        search: "",
                        sort_by_key: "",
                        sort_by_value: "asc",
                      })
                    );
                    setSnackbarMessage(res.payload.message);
                    setSnackbarVariant("success");
                    setShowSnackbar(true);
                    resetForm();
                    handleModalClose();
                  } else {
                    setSnackbarMessage(res.payload.message);
                    setErrors(res.payload.data);
                    setSnackbarVariant("danger");
                    setShowSnackbar(true);
                  }
                } catch (error) {
                  setSnackbarMessage("Failed to add organization type: " + error.message);
                  setSnackbarVariant("danger");
                  setShowSnackbar(true);
                } finally {
                  setIsSubmitting(false);
                }
              }}
            >
              {({
                submitForm,
                handleChange,
                setFieldValue,
                errors,
                touched,
                values,
              }) => {
                submitFormRef.current = submitForm;
                return (
                  <Form encType="multipart/form-data" className="">
                    <Row className="g-3">
                      <Col xl={12} lg={12} md={12} xs={12}>
                        <label className="form-label ms-1">Name</label>
                        <Field
                          name="name"
                          type="text"
                          className={`form-control shadow-none ${
                            errors.name && touched.name ? "is-invalid" : ""
                          }`}
                          placeholder="Enter name"
                          onChange={handleChange}
                        />
                        {errors.name && touched.name ? (
                          <div className="invalid-feedback ms-1 d-block">
                            {errors.name}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body> 
          <Modal.Footer className="d-flex align-items-center justify-content-end  py-2 px-4 add-reseller-footer">
            <Button
              variant="link"
              className="close-btn fw-medium m-0"
              onClick={handleModalClose}
            >
              Close
            </Button>
            <Button
              type="button"
              variant="link"
              className="next-btn fw-medium m-0"
              onClick={() => submitFormRef.current && submitFormRef.current()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Adding...
                </>
              ) : (
                "Add"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
