import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Dropdown,
  ButtonGroup,
  Modal,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import {
  deleteOrganizationType,
  fetchOrganizationTypes,
} from "../../../store/organizationSlice";
import { Container, Col, Row } from "react-bootstrap";
import PaginationComponent from "../pagination/pagination";
import { useLoader } from "../../../context/LoaderContext";
import {
  MagnifyingGlass,
  ArrowUp,
  ArrowDown,
  DotsThreeVertical,
  Pencil,
  Trash,
  X,
  Warning,
  CheckCircle,
} from "../../../constant/icons/icons";

export default function List({ editHandler, handleOpenModal }) {
  const { setLoading } = useLoader();
  const limit = 10;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [organizationTypeToDelete, setOrganizationTypeToDelete] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { total, organizationTypes } = useSelector((state) => ({
    total: state.organization?.total,
    organizationTypes: state.organization?.organization_types,
  }));
  const [openDropdown, setOpenDropdown] = useState({ id: null, type: null });

  const toastMessage = localStorage.getItem("toastMessageOrganizationType");

  useEffect(() => {
    if (toastMessage) {
      setShowSnackbar(true);
      setSnackbarMessage(toastMessage);
      setSnackbarVariant("success");
      localStorage.removeItem("toastMessageOrganizationType");

      if (toastMessage === "Validation failed") {
        setShowSnackbar(true);
        setSnackbarMessage(toastMessage);
        setSnackbarVariant("danger");
        localStorage.removeItem("toastMessageOrganizationType");
      }
    }
  }, [toastMessage]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
      role="button"
    >
      {children}
    </div>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        {/* <ArrowUp
          size={16}
          onClick={() => handleSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "asc" ? "black" : "#ccc",
          }}
        /> */}

        {/* Custom Icons */}
        <ArrowUp
          size={14}
          color={isActive && sortOrder === "asc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleSort(key, "asc")}
        />

        {/* <ArrowDown
          size={16}
          onClick={() => handleSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "desc" ? "black" : "#ccc",
          }}
        /> */}

        {/* Custom Icons */}
        <ArrowDown
          size={14}
          color={isActive && sortOrder === "desc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleSort(key, "desc")}
        />
      </>
    );
  };

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleDropdownToggle = (id, type) => {
    if (openDropdown.id === id && openDropdown.type === type) {
      // Close the currently open dropdown
      setOpenDropdown({ id: null, type: null });
    } else {
      // Open the new dropdown and close any others
      setOpenDropdown({ id: id, type: type });
    }
  };

  const ActionMenu = ({ organizationType, isOpen, toggleMenu }) => (
    <Dropdown
      drop="down-centered"
      as={ButtonGroup}
      className="action-dropdown w-100 d-inline-block"
      show={isOpen}
      onToggle={() => handleDropdownToggle(organizationType.id, "action")}
    >
      <Dropdown.Toggle
        id={`dropdown-split-basic-${organizationType.id}`}
        className="p-0"
        variant="link"
        as={CustomToggle}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu(organizationType.id);
        }}
      >
        <DotsThreeVertical size={17} color="#637381" weight="bold" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="action-dr-menu z-1">
        <Dropdown.Item
          eventKey="1"
          className="d-flex align-items-center dr-item item-orange"
          onClick={(e) => {
            e.stopPropagation();
            editHandler(organizationType.id);
          }}
        >
          <span>
            <Pencil size={15} color="#637381" weight="regular" />
          </span>
          <p className="mb-0">Edit</p>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="3"
          className="d-flex align-items-center dr-item item-red"
          onClick={(e) => {
            e.stopPropagation();
            promptDeleteOrganizationType(organizationType);
            toggleMenu(null); // Close the menu after action
          }}
        >
          <span>
            <Trash size={15} color="#637381" weight="regular" />
          </span>
          <p className="mb-0">Delete</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const promptDeleteOrganizationType = (organizationType) => {
    setOrganizationTypeToDelete(organizationType);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteOrganizationType = async () => {
    if (organizationTypeToDelete) {
      try {
        const res = await dispatch(
          deleteOrganizationType(organizationTypeToDelete.id)
        );
        if (res.payload.success) {
          setSnackbarMessage("Organization Type deleted successfully");
          setSnackbarVariant("success");
          dispatch(
            fetchOrganizationTypes({
              offset: currentPage * limit,
              limit,
              search: searchTerm,
            })
          );
        } else {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
        }
      } catch (error) {
        setSnackbarMessage("Error deleting organization type");
        setSnackbarVariant("danger");
      } finally {
        setShowSnackbar(true);
        setShowDeleteConfirmation(false);
        setOrganizationTypeToDelete(null);
      }
    }
  };

  const getOrganizationTypeList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(
        fetchOrganizationTypes({
          offset: currentPage,
          limit,
          search: searchTerm,
          sort_by_key: sortKey,
          sort_by_value: sortOrder,
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    currentPage,
    limit,
    searchTerm,
    sortKey,
    sortOrder,
    setLoading,
  ]);

  useEffect(() => {
    getOrganizationTypeList();
  }, [getOrganizationTypeList]);

  return (
    <>
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        dialogClassName="modal-dialog-scrollable add-reseller-popup"
        centered
      >
        <Modal.Header className="add-reseller-header d-inline-block p-0  border-bottom-0">
          <div className="d-flex align-items-center justify-content-between gap-3 br-bottom py-3 px-4">
            <h5 className="fw-semibold mb-0">Delete Organization Type</h5>
            <div className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center x-arrow">
              <X
                size={18}
                color=""
                weight="bold"
                onClick={() => setShowDeleteConfirmation(false)}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="py-4 delete-reseller-content">
          <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
            <div className="rounded-2 d-flex align-items-center justify-content-center error-show-icon">
              <Warning size={46} color="#dc3545" weight="regular" />
            </div>
            <p className="mb-0 fw-medium text-center">
              Are you sure you want to delete{" "}
              <b>{organizationTypeToDelete?.name}</b>.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex align-items-center justify-content-center pt-2  pb-4 px-4 border-top-0 add-reseller-footer">
          <Button
            variant="link"
            className="close-btn fw-medium m-0"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            variant="link"
            className="next-btn fw-medium m-0"
            onClick={() => handleDeleteOrganizationType()}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6 pb-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div>
                <h3 className="mb-0 fw-semibold pg-title text-white">
                  Organization Type
                </h3>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative rounded-3 bg-white  tablecontainer">
              <div className="d-flex align-items-center justify-content-between gap-3 reseller-sea-btn">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search "
                    className="searchbar outline-none border-0 shadow-none "
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setCurrentPage(0);
                    }}
                  />
                  <MagnifyingGlass
                    size={18}
                    color="#757779"
                    className="position-absolute top-50 start-0 ms-4 translate-middle"
                    weight="regular"
                  />
                </div>

                <Button
                  variant="primary"
                  className="fw-medium"
                  onClick={() => handleOpenModal(true)}
                >
                  Add Organization Type
                </Button>
              </div>

              <div className="rounded-2 custom_table mt-5">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Name</p>
                          <span
                            className="d-flex align-items-center"
                            onClick={() => handleSort("name")}
                          >
                            {renderSortIcons("name")}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center justify-content-center gap-th">
                          <p className="mb-0">Action</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {organizationTypes?.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center border-bottom-0">
                          <p className="mt-5 mb-0 no-data text-capitalize">
                            No data available
                          </p>
                        </td>
                      </tr>
                    ) : (
                      organizationTypes?.map((organizationType) => (
                        <tr
                          key={organizationType.id}
                          className="cursor-pointer position-relative"
                        >
                          <td>{organizationType.name}</td>
                          <td className="text-center">
                            <ActionMenu
                              organizationType={organizationType}
                              isOpen={
                                openDropdown.id === organizationType.id &&
                                openDropdown.type === "action"
                              }
                              toggleMenu={() =>
                                handleDropdownToggle(
                                  organizationType.id,
                                  "action"
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              {total > limit && (
                <div className="pt-4">
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
